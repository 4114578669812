import React, { useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import plumbing from '../../../assets/renovation/plomberie.png';
import plastering from '../../../assets/renovation/platrerie.png';
import walls from '../../../assets/renovation/walls.png';
import furnitureOnDemand from '../../../assets/renovation/furniture_on_demand.png';
import elevation from '../../../assets/renovation/elevation.png';
import electricity from '../../../assets/renovation/electricity.png';
import woodWork from '../../../assets/renovation/wood_work.png';
import floors from '../../../assets/renovation/floors.png';
import roofing from '../../../assets/renovation/toiture.png';
import facilities from '../../../assets/renovation/facilities.png';
import { useUpdateProperty } from '../../../hooks/mutate/update-property-mutation.hook';
import { AccordionContainer, GridContainerFormInput, SubmitButtonForm } from '../../components/containerform';
import { CurrencyTextFieldCustom } from '../../../common/inputs/currencytextfield';
import { BooleanWithIconRow } from '../../../common/inputs/booleanwidthicon-row';

interface IFormInput {
  plumbing: boolean,
  plumbingPrice: string,
  plastering: boolean
  plasteringPrice: string,
  walls: boolean
  wallsPrice: string,
  furnitureOnDemand: boolean
  furnitureOnDemandPrice: string,
  elevation: boolean
  elevationPrice: string,
  electricity: boolean
  electricityPrice: string,
  woodWork: boolean
  woodWorkPrice: string,
  floors: boolean
  floorsPrice: string,
  roofing: boolean
  roofingPrice: string,
  facilities: boolean
  facilitiesPrice: string,
}

function BuildingTrade({
  indexAccordion, setIndexAccordion, index, indexMax, title, data, queryInfo,
}:any) {
  const [searchParams] = useSearchParams();
  const {
    control, handleSubmit, reset, watch,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return {
        ...data,
      };
    }, [data]),
  });

  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    reset({
      ...data,
    });
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: () => {
      queryInfo.refetch();
      // eslint-disable-next-line no-param-reassign
      setIndexAccordion(indexAccordion += 1);
      // eslint-disable-next-line no-param-reassign
      navigate(`/form/simulation${search}`);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        renovation: {
          professionNeeded: {
            plumbing: form?.plumbing,
            plumbingPrice: !watch('plumbing') ? null : Number(form?.plumbingPrice),
            plastering: form?.plastering,
            plasteringPrice: !watch('plastering') ? null : Number(form?.plasteringPrice),
            walls: form?.walls,
            wallsPrice: !watch('walls') ? null : Number(form?.wallsPrice),
            furnitureOnDemand: form?.furnitureOnDemand,
            furnitureOnDemandPrice: !watch('furnitureOnDemand') ? null : Number(form?.furnitureOnDemandPrice),
            elevation: form?.elevation,
            elevationPrice: !watch('elevation') ? null : Number(form?.elevationPrice),
            electricity: form?.electricity,
            electricityPrice: !watch('electricity') ? null : Number(form?.electricityPrice),
            woodWork: form?.woodWork,
            woodWorkPrice: !watch('woodWork') ? null : Number(form?.woodWorkPrice),
            floors: form?.floors,
            floorsPrice: !watch('floors') ? null : Number(form?.floorsPrice),
            roofing: form?.roofing,
            roofingPrice: !watch('roofing') ? null : Number(form?.roofingPrice),
            facilities: form?.facilities,
            facilitiesPrice: !watch('facilities') ? null : Number(form?.facilitiesPrice),
          },
        },
      },
    };
    mutate({ ...formValid });
  };

  return (
    <AccordionContainer
      title={title}
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
      index={index}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box paddingX={{ xs: 0, sm: 7 }}>
          <Box>
            <GridContainerFormInput
              xsCol={2}
              smCol={4}
            >
              <Grid item xs={1}>
                <BooleanWithIconRow
                  height={100}
                  control={control}
                  name="plumbing"
                  watch={watch}
                  label="Plomberie"
                  icon={plumbing}
                />
                {watch('plumbing')
                  && (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="plumbingPrice"
                      label="Prix"
                      buildingTrade
                    />
                  )}
              </Grid>
              <Grid item xs={1}>
                <BooleanWithIconRow
                  height={100}
                  control={control}
                  name="plastering"
                  watch={watch}
                  label="Travaux d‘isolation"
                  icon={plastering}
                />
                {watch('plastering')
                  && (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="plasteringPrice"
                      label="Prix"
                      buildingTrade
                    />
                  )}
              </Grid>
              <Grid item xs={1}>
                <BooleanWithIconRow
                  height={100}
                  control={control}
                  name="walls"
                  watch={watch}
                  label="Revêtements muraux"
                  icon={walls}
                />
                {watch('walls')
                  && (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="wallsPrice"
                      label="Prix"
                      buildingTrade
                    />
                  )}
              </Grid>
              <Grid item xs={1}>
                <BooleanWithIconRow
                  height={100}
                  control={control}
                  name="furnitureOnDemand"
                  watch={watch}
                  label="Mobilier sur mesure"
                  icon={furnitureOnDemand}
                />
                {watch('furnitureOnDemand')
                  && (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="furnitureOnDemandPrice"
                      label="Prix"
                      buildingTrade
                    />
                  )}
              </Grid>
              <Grid item xs={1}>
                <BooleanWithIconRow
                  height={100}
                  control={control}
                  name="elevation"
                  watch={watch}
                  label="Extension / surélévation"
                  icon={elevation}
                />
                {watch('elevation')
                  && (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="elevationPrice"
                      label="Prix"
                      buildingTrade
                    />
                  )}
              </Grid>
              <Grid item xs={1}>
                <BooleanWithIconRow
                  height={100}
                  control={control}
                  name="electricity"
                  watch={watch}
                  label="Électricité"
                  icon={electricity}
                />
                {watch('electricity')
                  && (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="electricityPrice"
                      label="Prix"
                      buildingTrade
                    />
                  )}
              </Grid>
              <Grid item xs={1}>
                <BooleanWithIconRow
                  height={100}
                  control={control}
                  name="woodWork"
                  watch={watch}
                  label="Menuiseries extérieures"
                  icon={woodWork}
                />
                {watch('woodWork')
                  && (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="woodWorkPrice"
                      label="Prix"
                      buildingTrade
                    />
                  )}
              </Grid>
              <Grid item xs={1}>
                <BooleanWithIconRow
                  height={100}
                  control={control}
                  name="floors"
                  watch={watch}
                  label="Revêtements de sol"
                  icon={floors}
                />
                {watch('floors')
                  && (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="floorsPrice"
                      label="Prix"
                      buildingTrade
                    />
                  )}
              </Grid>
              <Grid item xs={1}>
                <BooleanWithIconRow
                  height={100}
                  control={control}
                  name="roofing"
                  watch={watch}
                  label="Toiture"
                  icon={roofing}
                />
                {watch('roofing')
                  && (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="roofingPrice"
                      label="Prix"
                      buildingTrade
                    />
                  )}
              </Grid>
              <Grid item xs={1}>
                <BooleanWithIconRow
                  height={100}
                  control={control}
                  name="facilities"
                  watch={watch}
                  label="Mobilier et équipements"
                  icon={facilities}
                />
                {watch('facilities')
                  && (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="facilitiesPrice"
                      label="Prix"
                      buildingTrade
                    />
                  )}
              </Grid>
            </GridContainerFormInput>
            <Box marginTop={{ xs: 3, sm: 'inherit' }} />
            <SubmitButtonForm index={index} indexMax={indexMax} />
          </Box>
        </Box>
      </Box>
    </AccordionContainer>
  );
}

export {
  BuildingTrade,
};
