import { Box } from '@mui/material';
import React from 'react';

function MaxWidthContainer({ children }: any) {
  return (
    <Box sx={{
      width: '100%',
      maxWidth: '1400px',
      display: 'flex',
      margin: '0 auto',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      {children}
    </Box>
  );
}

export {
  MaxWidthContainer,
};
