import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box, Grid, styled, TextField, Typography,
} from '@mui/material';
import { NumberFormatCustom } from './format/number-format-custom';

function CurrencyTextFieldCustom({
  control, name, label, rules = null, size = 'small', variant = 'outlined', buildingTrade = false,
}: any) {
  return (
    <Grid item height={80}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field, fieldState,
        }) => {
          return (
            <Box
              sx={{
                paddingX: buildingTrade ? 2 : 'inherit',
              }}
            >
              <CssTextField
                label={label}
                value={field.value || ''}
                variant={variant}
                placeholder="0,00 €"
                size={size}
                onChange={(e) => field.onChange(e.target.value)}
                onBlur={field.onBlur}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                }}
                fullWidth
              />
              {fieldState?.error?.message
              && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
            </Box>
          );
        }}
      />
    </Grid>
  );
}

const CssTextField = styled(TextField)(({ theme }) => ({
  label: {
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
  input: {
    color: theme.palette.text.primary,
  },
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '.css-ke91x2-MuiInputBase-root-MuiInput-root:before': {
    borderBottomColor: theme.palette.primary.main,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.primary.main,
  },
}));

export {
  CurrencyTextFieldCustom,
};
