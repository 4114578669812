import React, { useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import ascenseur from '../../../assets/arrangement/ascenseur.png';
import gardien from '../../../assets/arrangement/caretaker.png';
import cave from '../../../assets/arrangement/cave.png';
import parking from '../../../assets/arrangement/parking.png';
import jardin from '../../../assets/arrangement/jardin.png';
import box from '../../../assets/arrangement/box.png';
import { AddOrRemoveField } from '../../../common/inputs/addorremovefield';
import terrasse from '../../../assets/arrangement/terrasse.png';
import balcon from '../../../assets/arrangement/balcon.png';
import { SurfaceTextField } from '../../../common/inputs/surfacetextfield';
import { BooleanWithIcon } from '../../../common/inputs/booleanwithicon';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import { useUpdateProperty } from '../../../hooks/mutate/update-property-mutation.hook';
import {
  AccordionContainer, GridContainerFormInput, SubmitButtonForm,
} from '../../components/containerform';
import { AutocompleteCustom } from '../../../common/inputs/autocompletecustom';
import { internetFiberOptions } from '../../../data/data';

interface IFormInput {
  basement_number: number
  parking_lot_number: number
  elevator_number: number
  garden_number: number
  box_number: number
  terrace_number: number,
  balcony_number: number,
  caretaker_number: boolean,
  internet_fiber: boolean,
  terrace_surface: string
  garden_surface: string,
  balcony_surface: string,
  intercom_system_digital_code: string,
  intercom_system_name: string,
}

function Facilities({
  indexAccordion, setIndexAccordion, index, indexMax, title, data, queryInfo,
}: any) {
  const [searchParams] = useSearchParams();

  const format = {
    ...data,
    internet_fiber: internetFiberOptions?.find((f) => f?.value === data?.internet_fiber),
    basement_number: data?.basement_number || 0,
    parking_lot_number: data?.parking_lot_number || 0,
    elevator_number: data?.elevator_number || 0,
    garden_number: data?.garden_number || 0,
    box_number: data?.box_number || 0,
    terrace_number: data?.terrace_number || 0,
    balcony_number: data?.balcony_number || 0,
  };

  const {
    control, handleSubmit, watch, reset,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return { ...format };
    }, [data]),
  });

  useEffect(() => {
    reset({ ...format });
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
      // eslint-disable-next-line no-param-reassign
      setIndexAccordion(indexAccordion += 1);
      // eslint-disable-next-line no-param-reassign
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        arrangement: {
          basement_number: Number(form?.basement_number),
          parking_lot_number: Number(form?.parking_lot_number),
          elevator_number: Number(form?.elevator_number),
          garden_number: Number(form?.garden_number),
          box_number: Number(form?.box_number),
          balcony_number: Number(form?.balcony_number),
          terrace_number: Number(form?.terrace_number),
          terrace_surface: Number(form?.terrace_surface),
          garden_surface: Number(form?.garden_surface),
          balcony_surface: Number(form?.balcony_surface),
          internet_fiber: form?.internet_fiber?.value,
          caretaker_number: form?.caretaker_number,
          intercom_system_digital_code: form?.intercom_system_digital_code,
          intercom_system_name: form?.intercom_system_name,
        },
      },
    };
    mutate({ ...formValid });
  };

  return (
    <AccordionContainer
      title={title}
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
      index={index}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box paddingX={{ xs: 0, sm: 7 }}>
          <Box>
            <GridContainerFormInput
              xsCol={2}
              smCol={4}
            >
              <Grid item xs={1}>
                <AddOrRemoveField
                  control={control}
                  name="basement_number"
                  watch={watch}
                  label="Cave"
                  icon={cave}
                />
              </Grid>
              <Grid item xs={1}>
                <AddOrRemoveField
                  control={control}
                  name="parking_lot_number"
                  watch={watch}
                  label="Parking"
                  icon={parking}
                />
              </Grid>
              <Grid item xs={1}>
                <AddOrRemoveField
                  control={control}
                  name="garden_number"
                  watch={watch}
                  label="Jardin"
                  icon={jardin}
                />
              </Grid>
              <Grid item xs={1}>
                <AddOrRemoveField
                  control={control}
                  name="box_number"
                  watch={watch}
                  label="Box"
                  icon={box}
                />
              </Grid>
              <Grid item xs={1}>
                <AddOrRemoveField
                  control={control}
                  name="elevator_number"
                  watch={watch}
                  label="Ascenseur"
                  icon={ascenseur}
                />
              </Grid>
              <Grid item xs={1}>
                <AddOrRemoveField
                  control={control}
                  name="terrace_number"
                  watch={watch}
                  label="Terrasse"
                  icon={terrasse}
                />
              </Grid>
              <Grid item xs={1}>
                <AddOrRemoveField
                  control={control}
                  name="balcony_number"
                  watch={watch}
                  label="Balcon"
                  icon={balcon}
                />
              </Grid>
              <Grid item xs={1}>
                <BooleanWithIcon
                  control={control}
                  name="caretaker_number"
                  watch={watch}
                  label="Gardien"
                  icon={gardien}
                />
              </Grid>
              <Grid item xs={4} marginTop={3}>
                <AutocompleteCustom
                  control={control}
                  tab={internetFiberOptions}
                  name="internet_fiber"
                  label="Fibre"
                />
                <TextFieldCustom
                  control={control}
                  name="intercom_system_name"
                  label="Nom interphone"
                />
                <TextFieldCustom
                  control={control}
                  name="intercom_system_digital_code"
                  label="Digicode"
                />
                {
                  watch('terrace_number') > 0
                  && (
                    <SurfaceTextField
                      control={control}
                      name="terrace_surface"
                      label="Superficie Terrasse"
                    />
                  )
                }
                {
                  watch('garden_number') > 0
                  && (
                    <SurfaceTextField
                      control={control}
                      name="garden_surface"
                      label="Superficie Jardin"
                    />
                  )
                }
                {
                  watch('balcony_number') > 0
                  && (
                    <SurfaceTextField
                      control={control}
                      name="balcony_surface"
                      label="Superficie Balcon"
                    />
                  )
                }
              </Grid>
            </GridContainerFormInput>
            <SubmitButtonForm index={index} indexMax={indexMax} />
          </Box>
        </Box>
      </Box>
    </AccordionContainer>
  );
}

export {
  Facilities,
};
