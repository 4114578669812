import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Description } from '../components/description';
import { Arrangement } from '../components/arrangement';
import { Summary } from '../components/summary';
import { Conclusion } from '../components/conclusion';
import { GeneralInformation } from '../components/generalinformation';
import { Facilities } from '../components/facilities';
import { FutureLayout } from '../components/future-layout';

function Informations({ queryInfo }: any) {
  const [indexAccordion, setIndexAccordion] = useState<any>(0);
  const indexMax = 7;
  const data = queryInfo?.data?.getPropertiesById;

  return (
    <Box>
      <Summary
        title="Localisation"
        indexAccordion={indexAccordion}
        setIndexAccordion={setIndexAccordion}
        index={0}
        indexMax={indexMax}
        queryInfo={queryInfo}
        data={data}
      />
      <Description
        title="Description du bien"
        indexAccordion={indexAccordion}
        setIndexAccordion={setIndexAccordion}
        index={1}
        indexMax={indexMax}
        queryInfo={queryInfo}
        data={data?.description}
      />
      <Arrangement
        title="Aménagement"
        indexAccordion={indexAccordion}
        setIndexAccordion={setIndexAccordion}
        index={2}
        indexMax={indexMax}
        queryInfo={queryInfo}
        data={data?.arrangement}
      />
      <Facilities
        title="Équipement"
        indexAccordion={indexAccordion}
        setIndexAccordion={setIndexAccordion}
        index={3}
        indexMax={indexMax}
        queryInfo={queryInfo}
        data={data?.arrangement}
      />
      <FutureLayout
        title="Pièces à créer"
        indexAccordion={indexAccordion}
        setIndexAccordion={setIndexAccordion}
        index={4}
        indexMax={indexMax}
        queryInfo={queryInfo}
        data={data?.arrangement?.futureLayout}
      />
      <GeneralInformation
        title="Informations sur la copropriété"
        indexAccordion={indexAccordion}
        setIndexAccordion={setIndexAccordion}
        index={5}
        indexMax={indexMax}
        queryInfo={queryInfo}
        data={data?.general_information}
      />
      <Conclusion
        title="Contexte de la vente"
        indexAccordion={indexAccordion}
        setIndexAccordion={setIndexAccordion}
        indexMax={indexMax}
        index={6}
        queryInfo={queryInfo}
        data={data?.conclusion}
      />
    </Box>
  );
}

export {
  Informations,
};
