import React, { useState } from 'react';
import {
  Link, useNavigate,
} from 'react-router-dom';
import {
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { MaxWidthContainer } from '../../../utils/components/max-width-container';
import logoLsi from '../../../assets/common/logo_lsi_blanc.svg';

function DesktopHeader({ menu, queryUser, logout }: any) {
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Box
      display={{ xs: 'none', md: 'inherit' }}
      width="100%"
      height="100px"
      zIndex={1000}
      sx={{
        bgcolor: 'secondary.main',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      }}
    >
      <MaxWidthContainer>
        <Box
          width="90%"
          height="100px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Box
            width="20%"
            height="100%"
            sx={{ cursor: 'pointer' }}
            display="flex"
            marginLeft={{ xs: 3, xl: 1 }}
            alignItems="center"
            onClick={() => navigate('/')}
          >
            <Box
              component="img"
              sx={{
                objectFit: 'cover',
                height: '40%',
              }}
              src={logoLsi}
            />
          </Box>
          <Box
            width="60%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingX={{ xs: 10, xl: 15 }}
          >
            {menu.map((e: any, i: any) => {
              return (
                <Box key={e + i.toString()} paddingX={3}>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={`/${e.url}`}
                  >
                    <Typography
                      color="text.secondary"
                      sx={{ textDecoration: 'none' }}
                      variant="body1"
                      fontWeight="500"
                    >
                      {e.value}
                    </Typography>
                  </Link>
                </Box>
              );
            })}
          </Box>
          <Box
            width="20%"
            height="100%"
            display="flex"
            marginRight={{ xs: 3, xl: 1 }}
            alignItems="center"
            justifyContent="flex-end"
          >
            <LightTooltip
              open={showTooltip}
              onOpen={() => setShowTooltip(true)}
              onClose={() => setShowTooltip(false)}
              title={(
                <Box
                  display="flex"
                  paddingX={4}
                  paddingY={2}
                  width="240px"
                  alignItems="center"
                  justifyContent="space-around"
                  flexDirection="column"
                >
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Typography
                      color="text.primary"
                      variant="caption"
                      textAlign="center"
                    >
                      {queryUser?.data?.findUserLoggedIn?.email}
                    </Typography>
                    <Box display="flex" justifyContent="center">
                      <Typography
                        color="text.primary"
                        variant="caption"
                        sx={{ marginRight: 1 }}
                      >
                        {queryUser?.data?.findUserLoggedIn?.firstName}
                      </Typography>
                      <Typography
                        color="text.primary"
                        variant="caption"
                      >
                        {queryUser?.data?.findUserLoggedIn?.lastName}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    color="text.primary"
                    sx={{
                      fontWeight: 'bold',
                      marginY: 2,
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() => logout()}
                  >
                    Se déconnecter
                  </Typography>
                  <Box display="flex" width="100%" justifyContent="space-between" alignItems="flex-end">
                    <Box
                      component="a"
                      sx={{ textDecoration: 'none' }}
                      href="https://lessecretsdelimmo.fr/mentions-legales"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Typography color="primary" variant="caption" gutterBottom>
                        Mentions légales
                      </Typography>
                    </Box>
                    <Box
                      component="a"
                      href="https://lessecretsdelimmo.fr/confidentialite-donnees-personnelles"
                      sx={{ textDecoration: 'none' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Typography color="primary" variant="caption">
                        Données personnelles
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              placement="bottom"
            >
              <MenuIcon
                onClick={() => setShowTooltip(!showTooltip)}
                sx={{
                  color: (theme) => theme.palette.background.paper, cursor: 'pointer', textTransform: 'uppercase',
                }}
              />
            </LightTooltip>
          </Box>
        </Box>
      </MaxWidthContainer>
    </Box>
  );
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: 'none',
    borderRadius: '5px',
    border: '0.3px solid black',
  },
}));

export {
  DesktopHeader,
};
