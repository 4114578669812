import React from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  Box, Button, IconButton, Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddressCustom from '../../../common/inputs/autocompleteadress';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import { Text } from '../pages';

function FormLeft({ control }: any) {
  const {
    fields, append, remove,
  } = useFieldArray<any>({
    control,
    name: 'ad_link',
  });

  const defaultValue = {
    link: null,
  };

  return (
    <Box
      width={{ xs: '100%', md: '50%' }}
    >
      <Typography
        variant="h5"
        fontWeight={600}
        marginBottom={2}
        width="90%"
        textAlign="left"
      >
        Ajouter
        {' '}
        <Text>
          le lien
        </Text>
        {' '}
        de l’annonce
      </Typography>
      {fields.map((item, indexField) => (
        <Box key={item + indexField.toString()} display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
          <Box width={{ xs: '100%', md: 500 }} marginRight={{ xs: 0, md: 2 }}>
            <TextFieldCustom
              size="small"
              control={control}
              name={`ad_link[${indexField}].link`}
            />
          </Box>
          <Button
            sx={{
              display: { xs: 'inherit', md: 'none' },
              padding: 0,
              minWidth: 0,
              marginTop: -3,
              marginBottom: 2,
              width: '100%',
              height: 40,
              color: 'text.secondary',
            }}
            variant="contained"
            color="primary"
            onClick={() => remove(indexField)}
          >
            <ClearIcon />
          </Button>
          <IconButton
            sx={{
              display: { xs: 'none', md: 'inherit' },
              height: 40,
              width: 40,
            }}
            onClick={() => remove(indexField)}
            color="error"
          >
            <RemoveCircleIcon />
          </IconButton>
        </Box>
      ))}
      <Box display="flex">
        <Button
          type="button"
          sx={{
            cursor: 'pointer',
            textTransform: 'none',
            textDecoration: 'underline',
            transition: 'opacity 0.3s',
            fontSize: '16px',
            width: 'fit-content',
            ':hover': {
              opacity: 0.8,
            },
          }}
          startIcon={<AddCircleIcon />}
          color="primary"
          onClick={() => append(defaultValue)}
        >
          Ajouter un lien
        </Button>
      </Box>
      <Typography
        variant="h5"
        fontWeight={600}
        marginTop={3}
        marginBottom={2}
        width={{ xs: '90%', md: '100%' }}
        textAlign="left"
      >
        Ajouter
        {' '}
        <Text>
          l’adresse
        </Text>
        {' '}
        du bien
      </Typography>
      <Box width={{ xs: '100%', md: 500 }}>
        <AddressCustom
          size="small"
          control={control}
          rules={{ required: 'Veuillez renseigner votre adresse' }}
          name="street_address"
        />
      </Box>
      <Typography
        variant="h5"
        fontWeight={600}
        marginBottom={2}
        width={{ xs: '90%', md: '100%' }}
        textAlign="left"
      >
        Complément
        {' '}
        <Text>
          d’adresse
        </Text>
        {' '}
        du bien
      </Typography>
      <Box width={{ xs: '100%', md: 500 }}>
        <TextFieldCustom
          size="small"
          control={control}
          name="address_details"
          placeholder="Bâtiment, porte, cage d'escalier..."
        />
      </Box>
    </Box>
  );
}

export {
  FormLeft,
};
