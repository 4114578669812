import React from 'react';
import {
  Routes, Route, Outlet, useSearchParams,
} from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { Menu } from '../../menu/pages';
import { TypeOfProperty } from '../../type-of-property/pages';
import { Layout } from '../../common/layout/components';
import { FormStart } from '../../form/form-start/pages';
import { PropertyList } from '../../property-list/pages';
import { Informations } from '../../form/informations/pages';
import { Documents } from '../../form/documents/pages';
import { Renovation } from '../../form/renovation/pages';
import { Simulation } from '../../form/simulation/pages';
import { flatTypeOptions } from '../../data/data';
import { ContainerForm } from '../../form/components/containerform';
import { useGetPropertiesById } from '../../hooks/query/get-properties-by-id-query.hook';

function AuthenticatedApp() {
  const [searchParams] = useSearchParams();
  const queryInfo = useGetPropertiesById(searchParams.get('propertyId'));

  return (
    <Layout>
      <Routes>
        <Route path="" element={<Menu />} />
        <Route path="property-list" element={<PropertyList />} />
        <Route path="form">
          <Route path="add-property" element={<TypeOfProperty />} />
          <Route path="form-start" element={<FormStart />} />
          <Route element={<Container queryInfo={queryInfo} />}>
            <Route path="info" element={<Informations queryInfo={queryInfo} />} />
            <Route path="renovation" element={<Renovation queryInfo={queryInfo} />} />
            <Route path="documents" element={<Documents queryInfo={queryInfo} />} />
            <Route path="simulation" element={<Simulation queryInfo={queryInfo} />} />
          </Route>
        </Route>
        <Route path="*" element={<Menu />} />
      </Routes>
    </Layout>
  );
}

function Container({ queryInfo }: any) {
  const data = queryInfo?.data?.getPropertiesById;
  return (
    <>
      {
        queryInfo?.isLoading && !queryInfo.isSuccess ? (
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="calc(100vh - 100px)"
          >
            <CircularProgress size={80} />
          </Box>
        ) : (
          <ContainerForm
            queryInfo={queryInfo}
            title={data?.description?.flat_type ? `${flatTypeOptions?.find((f) => f.value === data?.description?.flat_type)?.label} ${data?.description?.surface ? `de ${data?.description?.surface}m2` : ''} ${data?.localisation?.street_address?.locality ? `à ${data?.localisation?.street_address?.locality}` : '' || ''}` : ''}
          >
            <Outlet />
          </ContainerForm>
        )
      }
    </>
  );
}

export {
  AuthenticatedApp,
};
