import React from 'react';
import { DisplayPropertiesContainer } from '../containers/container-get-all-properties-by-user';

function PropertyList() {
  return (
    <DisplayPropertiesContainer />
  );
}

export {
  PropertyList,
};
