import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { MaxWidthContainer } from '../../../utils/components/max-width-container';
import logoLsi from '../../../assets/common/logo_lsi_blanc.svg';
import { LinkWithQuery } from '../../../utils/components/link-with-query-params';

function Footer({
  menu, disabled, logout, queryUser,
}: any) {
  const navigate = useNavigate();
  return (
    <Box
      width="100%"
      bgcolor="secondary.main"
      paddingY={{ xs: 5, sm: 10 }}
      display={{ xs: 'none', sm: 'flex' }}
    >
      <MaxWidthContainer>
        <Box display="flex" alignItems="center" width="100%" flexDirection={{ xs: 'column', sm: 'row' }}>
          <Box
            width={{ xs: '100%', sm: '50%' }}
            display="flex"
            marginBottom={{ xs: 4, sm: 'inherit' }}
            alignItems="flex-start"
            justifyContent={{ xs: 'center', sm: 'flex-start' }}
            onClick={() => navigate('/')}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Box
              component="img"
              sx={{
                objectFit: 'cover',
                width: '140px',
              }}
              src={logoLsi}
            />
          </Box>
          <Box width={{ xs: '100%', sm: '50%' }} display="flex" alignItems={{ xs: 'center', sm: 'inherit' }} justifyContent="space-around" flexDirection={{ xs: 'column', sm: 'row' }}>
            <Box
              height={{ xs: '160px', sm: '120px' }}
              display="flex"
              flexDirection="column"
              alignItems={{ xs: 'center', sm: 'flex-start' }}
            >
              {menu.map((e: any) => {
                if (disabled) return null;
                return (
                  <Box
                    key={e?.value}
                  >
                    <LinkWithQuery to={`/${e.url}`}>
                      <Typography
                        sx={{
                          ':hover': {
                            textDecoration: 'underline',
                          },
                        }}
                        color="text.secondary"
                        variant="body1"
                        fontWeight="400"
                      >
                        {e.value}
                      </Typography>
                    </LinkWithQuery>
                  </Box>
                );
              })}
            </Box>
            <Box
              width={{ xs: '100%', sm: 'inherit' }}
              height={{ xs: 'auto', sm: '120px' }}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems={{ xs: 'center', sm: 'flex-start' }}
            >
              <Box display={{ xs: 'flex', sm: 'inherit' }} flexDirection={{ xs: 'column', sm: 'inherit' }} alignItems={{ xs: 'center', sm: 'inherit' }}>
                <Typography color="text.secondary" variant="body2" marginTop={{ xs: 6, sm: 'inherit' }}>
                  {queryUser?.data?.findUserLoggedIn?.email}
                </Typography>
                <Typography
                  marginTop={{ xs: 2, sm: 'inherit' }}
                  color="error.main"
                  onClick={() => logout()}
                  variant="body2"
                  sx={{
                    cursor: 'pointer',
                    ':hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Se déconnecter
                </Typography>
              </Box>
              <Box
                width={{ xs: '100%', sm: 'inherit' }}
                display={{ xs: 'flex', sm: 'inherit' }}
                flexDirection={{ xs: 'row', sm: 'inherit' }}
                justifyContent={{ xs: 'space-around', sm: 'inherit' }}
                alignItems={{ xs: 'center', sm: 'inherit' }}
              >
                <Box
                  marginTop={{ xs: 3, sm: 'inherit' }}
                  component="a"
                  sx={{ textDecoration: 'none' }}
                  href="https://lessecretsdelimmo.fr/mentions-legales"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    sx={{
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    color="text.secondary"
                    variant="body2"
                  >
                    Mentions légales
                  </Typography>
                </Box>
                <Box
                  marginTop={{ xs: 3, sm: 'inherit' }}
                  component="a"
                  sx={{ textDecoration: 'none' }}
                  href="https://lessecretsdelimmo.fr/confidentialite-donnees-personnelles"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    sx={{
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    color="text.secondary"
                    variant="body2"
                  >
                    Données personnelles
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </MaxWidthContainer>
    </Box>
  );
}

export {
  Footer,
};
