import { useTheme } from '@mui/material';
import { useWindowSize } from './window-size.hook';

function useDeviceSize() {
  const size = useWindowSize();
  const theme = useTheme();

  const bigDesktopSize = size.width >= theme.breakpoints.values.lg;
  const desktopSize = size.width >= theme.breakpoints.values.md;
  const ipadSize = size.width >= theme.breakpoints.values.sm;
  const mobileSize = size.width >= theme.breakpoints.values.xs;

  return ({
    desktopSize, ipadSize, mobileSize, bigDesktopSize,
  });
}

export {
  useDeviceSize,
};
