import { useGetAgencyByIdQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetAgencyById = (getAgencyByIdId: string | any) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo = useGetAgencyByIdQuery(headerWithAuth, { getAgencyByIdId }, { enabled: !!accessToken && !!getAgencyByIdId });

  return queryInfo;
};

export { useGetAgencyById };
