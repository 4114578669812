import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Grid, Typography, Divider,
} from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import {
  GridContainerFormInput,
  AccordionContainer,
  SectionTitle,
  SubmitButtonForm,
} from '../../components/containerform';
import buanderie from '../../../assets/arrangement/buanderie.png';
import bureau from '../../../assets/arrangement/bureau.png';
import chambre from '../../../assets/arrangement/chambre.png';
import cuisine from '../../../assets/arrangement/cusine.png';
import salle_a_manger from '../../../assets/arrangement/salle_a_manger.png';
import salle_eau from '../../../assets/arrangement/salle_eau.png';
import salon from '../../../assets/arrangement/salon.png';
import salle_de_bain from '../../../assets/arrangement/salle_de_bain.png';
import { AddOrRemoveField } from '../../../common/inputs/addorremovefield';
import { useUpdateProperty } from '../../../hooks/mutate/update-property-mutation.hook';

interface IFormInput {
  bedroom_number: number
  living_room_number: number
  dining_room_number: number
  bathroom_number: number
  shower_room_number: number
  kitchen_number: number
  laundry_room_number: number
  office_number: number
  water_closet_number: number
}

function Arrangement({
  indexAccordion, setIndexAccordion, index, indexMax, title, data, queryInfo,
}: any) {
  const [toModifyArrangement, setToModifyArrangement] = useState(false);
  const [searchParams] = useSearchParams();
  const {
    control, handleSubmit, watch, reset,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return data;
    }, [data]),
  });

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'floors',
  });

  const defaultValue = {
    bedroom_number: 0,
    living_room_number: 0,
    dining_room_number: 0,
    bathroom_number: 0,
    shower_room_number: 0,
    kitchen_number: 0,
    laundry_room_number: 0,
    office_number: 0,
    water_closet_number: 0,
  };

  useEffect(() => {
    if (fields.length === 0) {
      append(defaultValue);
    }
  }, [fields]);

  useEffect(() => {
    reset(data);
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
      // eslint-disable-next-line no-param-reassign
      setIndexAccordion(indexAccordion += 1);
      // eslint-disable-next-line no-param-reassign
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        arrangement: {
          floors: [
            ...form.floors,
          ],
        },
      },
    };
    mutate({ ...formValid });
  };

  return (
    <AccordionContainer
      title={title}
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
      index={index}
      fields={fields}
      remove={remove}
      toModifyArrangement={toModifyArrangement}
      setToModifyArrangement={setToModifyArrangement}
      append={append}
      defaultValue={defaultValue}
      ifDisplayStage
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {fields?.map((item, indexField) => (
          <Box key={item?.id} paddingX={{ xs: 0, sm: 7 }}>
            <Box>
              { indexField > 0 && <Divider sx={{ marginBottom: 2 }} />}
              <Typography variant="h5" textAlign="center">{indexField === 0 ? '' : `Étage n°${indexField}`}</Typography>
              <SectionTitle text="Pièces de vie" />
              <GridContainerFormInput
                xsCol={2}
                smCol={4}
              >
                <Grid item xs={1}>
                  <AddOrRemoveField
                    control={control}
                    name={`floors[${indexField}].bedroom_number`}
                    watch={watch}
                    label="Chambre"
                    icon={chambre}
                  />
                </Grid>
                <Grid item xs={1}>
                  <AddOrRemoveField
                    control={control}
                    name={`floors[${indexField}].living_room_number`}
                    watch={watch}
                    label="Salon"
                    icon={salon}
                  />
                </Grid>
                <Grid item xs={1}>
                  <AddOrRemoveField
                    control={control}
                    name={`floors[${indexField}].dining_room_number`}
                    watch={watch}
                    label="Salle à manger"
                    icon={salle_a_manger}
                  />
                </Grid>
              </GridContainerFormInput>
              <SectionTitle text="Pièces d’eau" />
              <GridContainerFormInput
                xsCol={2}
                smCol={4}
              >
                <Grid item xs={1}>
                  <AddOrRemoveField
                    control={control}
                    name={`floors[${indexField}].bathroom_number`}
                    watch={watch}
                    label="Salle de bain"
                    icon={salle_de_bain}
                  />
                </Grid>
                <Grid item xs={1}>
                  <AddOrRemoveField
                    control={control}
                    name={`floors[${indexField}].shower_room_number`}
                    watch={watch}
                    label="Salle d’eau"
                    icon={salle_eau}
                  />
                </Grid>
                <Grid item xs={1}>
                  <AddOrRemoveField
                    control={control}
                    name={`floors[${indexField}].kitchen_number`}
                    watch={watch}
                    label="Cuisine"
                    icon={cuisine}
                  />
                </Grid>
                <Grid item xs={1}>
                  <AddOrRemoveField
                    control={control}
                    name={`floors[${indexField}].water_closet_number`}
                    watch={watch}
                    label="WC"
                    icon={cuisine}
                  />
                </Grid>
              </GridContainerFormInput>
              <SectionTitle text="Autres" />
              <GridContainerFormInput
                xsCol={2}
                smCol={4}
              >
                <Grid item xs={1}>
                  <AddOrRemoveField
                    control={control}
                    name={`floors[${indexField}].laundry_room_number`}
                    watch={watch}
                    label="Buanderie"
                    icon={buanderie}
                  />
                </Grid>
                <Grid item xs={1}>
                  <AddOrRemoveField
                    control={control}
                    name={`floors[${indexField}].office_number`}
                    watch={watch}
                    label="Bureau"
                    icon={bureau}
                  />
                </Grid>
              </GridContainerFormInput>
            </Box>
          </Box>
        ))}
        {fields.length > 0 && (
          <SubmitButtonForm index={index} indexMax={indexMax} />
        )}
      </Box>
    </AccordionContainer>
  );
}

export {
  Arrangement,
};
