import { useFindUserLoggedInQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useFindUserLoggedIn = () => {
  const { accessToken, headerWithAuth } = useGetAccessToken();

  const queryInfo = useFindUserLoggedInQuery(
    headerWithAuth,
    {},
    { enabled: !!accessToken },
  );

  return queryInfo;
};

export { useFindUserLoggedIn };
