import React from 'react';
import { DisplayProperties } from '../components/display-properties';

function DisplayPropertiesContainer() {
  return (
    <DisplayProperties />
  );
}

export {
  DisplayPropertiesContainer,
};
