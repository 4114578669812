import { useGetPropertiesByIdQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetPropertiesById = (propertyId: string | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo = useGetPropertiesByIdQuery(headerWithAuth, {
    propertyId: propertyId || '',
  }, { enabled: !!accessToken && !!propertyId });

  return queryInfo;
};

export {
  useGetPropertiesById,
};
