import React, { useState } from 'react';
import {
  Alert, Snackbar,
  Box, Button, styled, Typography, ButtonGroup, ClickAwayListener,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller, useForm } from 'react-hook-form';
// @ts-ignore
import ImageGallery from 'react-image-gallery';
import ClearIcon from '@mui/icons-material/Clear';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useSearchParams } from 'react-router-dom';
import 'react-image-gallery/styles/css/image-gallery.css';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { AllowedPropertyDocumentsLabels } from '../../../api';
import { useGetAccessToken } from '../../../hooks/auth/get-access-token.hook';
import { useUpdateProperty } from '../../../hooks/mutate/update-property-mutation.hook';
import { useDeleteDocumentsFromProperty } from '../../../hooks/mutate/delete-documents-from-property-mutation.hook';

interface IFormInput {
  file: string
}

function Carousel({ queryInfo }: any) {
  const [openSnack, setOpenSnack] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState({ text: '', type: '' });
  const [searchParams] = useSearchParams();
  const { accessToken } = useGetAccessToken();
  const [isPreview, setIsPreview] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const propertyId = searchParams.get('propertyId');
  const {
    control, handleSubmit, watch, setValue,
  } = useForm<IFormInput>({});

  const { enqueueSnackbar } = useSnackbar();

  const setIsPreviewMutate = useUpdateProperty({
    onSuccess: () => {
      setIsPreview(false);
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const deleteMutate = useDeleteDocumentsFromProperty({
    onSuccess: () => {
      enqueueSnackbar('Votre photo ou vidéo a bien été supprimé !');
      setIsDelete(false);
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const submitDocumentAsPreview = (id: string) => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        document: {
          isPreview: true,
          _id: id,
        },
      },
    };
    setIsPreviewMutate({ ...formValid });
  };

  const submitDeleteDocument = (id: string) => {
    const formValid: any = {
      documentId: id,
    };
    deleteMutate({ ...formValid });
  };

  const documentTypeImg = queryInfo?.data?.getPropertiesById?.propertyPictureDocuments;

  const images = documentTypeImg && documentTypeImg?.map((e: any) => ({
    original: e.url,
    thumbnail: e.url,
    mimeType: e?.mimeType || null,
    renderThumbInner: (item: any) => (
      <Box
        width="auto"
        height="70px"
        minHeight="70px"
        sx={{ cursor: 'pointer' }}
      >
        {item?.mimeType && item.mimeType.includes('video') ? (
          <Box position="relative" width="100%" height="100%">
            <Box
              component="video"
              height="70px"
              width="100%"
              muted
              controls={false}
              bgcolor="divider"
              sx={{
                position: 'relative',
              }}
            >
              <source
                src={e.url}
                type={e.mimeType === 'video/quicktime' ? 'video/mp4' : e.mimeType}
              />
            </Box>
            <Box
              display={isDelete ? 'inherit' : 'none'}
              position="absolute"
              onClick={() => submitDeleteDocument(e?._id)}
              width="100%"
              height="100%"
              sx={{
                filter: 'grayscale(1)',
              }}
              top={0}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  textAlign: 'center',
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  fontSize: '24px',
                  opacity: 1,
                }}
              >
                <DeleteIcon />
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box position="relative" width="100%" height="100%">
            <Box
              bgcolor="divider"
              component="img"
              width="100%"
              height="100%"
              src={e.url}
              alt="property img"
              position="relative"
              sx={{
                objectFit: 'contain',
                filter: isPreview ? e.isPreview ? 'grayscale(0)' : 'grayscale(1)' : 'grayscale(0)',
                position: 'relative',
              }}
            />
            <Box
              display={isPreview ? 'inherit' : 'none'}
              position="absolute"
              onClick={() => submitDocumentAsPreview(e?._id)}
              width="100%"
              height="100%"
              sx={{
                filter: 'grayscale(1)',
              }}
              top={0}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  textAlign: 'center',
                  width: '100%',
                  height: '100%',
                  fontSize: '24px',
                  opacity: e?.isPreview ? 1 : 0,
                  ':hover': {
                    opacity: 1,
                  },
                }}
              >
                ★
              </Typography>
            </Box>
            <Box
              display={isDelete ? 'inherit' : 'none'}
              position="absolute"
              onClick={() => submitDeleteDocument(e?._id)}
              width="100%"
              height="100%"
              sx={{
                filter: 'grayscale(1)',
              }}
              top={0}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  textAlign: 'center',
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  fontSize: '24px',
                  opacity: 1,
                }}
              >
                <DeleteIcon />
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    ),
    renderItem: (item: any) => (
      <Box
        width="100%"
        height={isFullScreen ? { xs: '90dvh', sm: '90vh' } : { xs: '220px', sm: '330px' }}
        bgcolor="divider"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          overflowX: isFullScreen ? 'hidden' : 'inherit',
          zIndex: isFullScreen ? 200000 : 'inherit',
        }}
      >
        {item?.mimeType && item.mimeType.includes('video')
          ? (
            <Box
              component="video"
              controls
              height="100%"
              width="100%"
            >
              <source
                src={e.url}
                type={e.mimeType === 'video/quicktime' ? 'video/mp4' : e.mimeType}
              />
            </Box>
          ) : (
            <Box
              component="img"
              width="100%"
              height="100%"
              sx={{ objectFit: 'contain', display: 'block' }}
              src={e.url}
              alt="property img"
            />
          )}
      </Box>
    ),
  }));

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
    setUploadMessage({ text: '', type: '' });
  };

  const onSubmit = (form: any): any => {
    setIsLoading(true);
    let wrongFormat = false;
    for (const file of form.file) {
      if (file?.type === 'image/heif') {
        wrongFormat = true;
      }
    }

    if (wrongFormat) {
      setOpenSnack(true);
      setUploadMessage({ text: "le format de l'un de vos fichiers n'est pas valide !", type: 'error' });
    } else {
      const formData = new FormData();
      for (const file of form.file) {
        formData.append('files', file);
      }
      formData.append('propertyId', propertyId ?? '');
      formData.append('label', AllowedPropertyDocumentsLabels.PROPERTY_PICTURE);

      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${accessToken}`);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
      };

      fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/property/upload`, requestOptions).then((response) => response.json())
        .then((result) => {
          if (result.success) {
            queryInfo.refetch();
            setUploadMessage({ text: 'Votre ou vos image(s) ont été importé avec succès !', type: 'success' });
            setOpenSnack(true);
            setValue('file', '');
            setIsLoading(false);
          }
        }).catch((err) => {
          setOpenSnack(true);
          setUploadMessage({ text: `Une erreur est survenue : ${err.message}`, type: 'error' });
          setIsLoading(false);
        });
    }
  };

  const checkFileName = (files: any) => {
    if (files) {
      let filesName = '';
      for (const file of files) {
        // eslint-disable-next-line no-multi-assign
        filesName = filesName += `${file?.name}\n`;
      }
      return filesName;
    }
    return 'Le fichier';
  };

  const handleClickAway = () => {
    setIsPreview(false);
    setIsDelete(false);
  };

  return (
    <Box paddingX={{ xs: 0, md: 7 }}>
      <Snackbar open={openSnack} onClose={handleCloseSnack} autoHideDuration={5000}>
        <Alert
          severity={uploadMessage?.type === 'success' ? 'success' : 'error'}
          onClose={handleCloseSnack}
          sx={{ width: '100%' }}
        >
          {uploadMessage?.text}
        </Alert>
      </Snackbar>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box
          position="relative"
          padding={{ xs: 'inherit', sm: '10px 24px' }}
          display="flex"
          marginBottom={2}
          flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        >
          {queryInfo.isSuccess && !queryInfo.isLoading && documentTypeImg && documentTypeImg?.length > 0
          && (
            <Box
              width={{ xs: '100%', sm: '75%' }}
              paddingX={{ xs: 0, sm: 1 }}
              marginBottom={{ xs: 4, sm: 0 }}
              sx={{
                '.image-gallery-left-nav': {
                  padding: '0 !important',
                  left: '10px',
                  height: 'fit-content !important',
                },
                '.image-gallery-right-nav': {
                  padding: '0 !important',
                  right: '10px',
                  height: 'fit-content !important',
                },
                '.image-gallery.fullscreen-modal': {
                  zIndex: 200000,
                },
                '.image-gallery-fullscreen-button': {
                  padding: '0 !important',
                  top: '20px',
                  right: '20px',
                  height: 'fit-content !important',
                },
                '.image-gallery-left-nav .image-gallery-svg': {
                  width: isFullScreen ? 'inherit' : '20px',
                  height: isFullScreen ? '50px' : '-moz-fit-content !important',
                },
                '.image-gallery-right-nav .image-gallery-svg': {
                  width: isFullScreen ? 'inherit' : '20px',
                  height: isFullScreen ? '50px' : '-moz-fit-content !important',
                },
                '.image-gallery-fullscreen-button .image-gallery-svg': {
                  width: isFullScreen ? 'inherit' : '20px',
                  height: isFullScreen ? '30px' : '-moz-fit-content !important',
                },
              }}
            >
              <ImageGallery
                items={images}
                showPlayButton={false}
                thumbnailPosition="bottom"
                onScreenChange={() => setIsFullScreen(!isFullScreen)}
              />
            </Box>
          )}
          <ButtonOptions
            documentTypeImg={documentTypeImg}
            submit={handleSubmit(onSubmit)}
            control={control}
            watch={watch}
            checkFileName={checkFileName}
            setValue={setValue}
            isLoading={isLoading}
            isPreview={isPreview}
            setIsPreview={setIsPreview}
            isDelete={isDelete}
            setIsDelete={setIsDelete}
          />
        </Box>
      </ClickAwayListener>
    </Box>
  );
}

function ButtonOptions({
  documentTypeImg, submit, control, watch, checkFileName, setValue, isLoading, isPreview, isDelete, setIsPreview, setIsDelete,
}: any) {
  return (
    <Box
      width={{ xs: '100%', sm: '25%' }}
      paddingX={{ xs: 0, sm: documentTypeImg?.length > 0 ? 1 : 0 }}
      marginBottom={{ xs: 2, sm: 0 }}
    >
      <Box
        component="form"
        onSubmit={submit}
      >
        <label htmlFor="contained-button-file">
          <Controller
            name="file"
            control={control}
            render={({ field }) => (
              <Input
                multiple
                id="contained-button-file"
                // eslint-disable-next-line no-shadow
                onChange={(elem) => {
                  // @ts-ignore
                  const file = elem.target.files ?? null;
                  if (!file) return;
                  field.onChange(file);
                }}
                type="file"
              />
            )}
          />
          <Button
            variant="outlined"
            component="span"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              height: { xs: 'inherit', sm: documentTypeImg?.length > 0 ? '100px' : 'inherit' },
            }}
            fullWidth
            endIcon={<CameraAltIcon />}
          >
            Importer une image
          </Button>
        </label>
        {watch('file')
          && (
            <Box display="flex" flexDirection="column">
              <Box
                bgcolor="text.secondary"
                marginTop={1}
                padding={1}
                display="flex"
                borderRadius="4px"
              >
                <Typography
                  variant="caption"
                  sx={{
                    width: '95%',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {
                    // @ts-ignore
                    checkFileName(watch('file'))
                  }
                </Typography>
                <Box
                  width="5%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setValue('file', '')}
                >
                  <ClearIcon fontSize="small" color="error" />
                </Box>
              </Box>
              <LoadingButton
                fullWidth
                sx={{
                  marginRight: 3, marginTop: 1, textTransform: 'none', fontWeight: 'bold',
                }}
                color="primary"
                loading={isLoading}
                variant="outlined"
                type="submit"
              >
                Télécharger
              </LoadingButton>
            </Box>
          )}
      </Box>
      <Box display={watch('file') ? 'none' : 'inherit'} marginY={2}>
        {documentTypeImg.length > 0
          && (
            <ButtonGroup variant="outlined" fullWidth>
              {!isDelete
                && (
                  <Button
                    fullWidth
                    onClick={() => {
                      setIsPreview(!isPreview);
                      setIsDelete(false);
                    }}
                    variant="outlined"
                    color={isPreview ? 'error' : 'primary'}
                    sx={{
                      textTransform: 'none',
                    }}
                  >
                    {isPreview ? 'Annuler' : 'Miniature'}
                  </Button>
                )}
              {!isPreview
                && (
                  <Button
                    fullWidth
                    onClick={() => {
                      setIsDelete(!isDelete);
                      setIsPreview(false);
                    }}
                    variant="outlined"
                    size="small"
                    color="error"
                    sx={{
                      textTransform: 'none',
                    }}
                  >
                    {isDelete ? 'Annuler' : 'Supprimer'}
                  </Button>
                )}
            </ButtonGroup>
          )}
      </Box>
      <Box>
        {isPreview
          && (
            <Typography variant="body2" fontWeight="bold" fontStyle="italic">
              ⚠️ Cliquez sur une des photos miniatures pour qu‘elle deviennent la photo principale ou appuyez sur annuler pour ne rien faire.
            </Typography>
          )}
        {isDelete
          && (
            <Typography variant="body2" fontWeight="bold" fontStyle="italic">
              ⚠️ Cliquez sur une des photos miniatures pour la supprimer ou appuyez sur annuler pour ne rien faire.
            </Typography>
          )}
      </Box>
    </Box>
  );
}

const Input = styled('input')({
  display: 'none',
});

export {
  Carousel,
};
