import React, { useEffect, useState } from 'react';
import {
  Box, Paper, Typography, Tab, Tabs, Chip, Accordion, AccordionSummary, AccordionDetails, Button, alpha, ButtonGroup, Grid,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { propertyStatusOptions } from '../../data/data';
import { useDeviceSize } from '../../hooks/utils/device-size.hook';

function ContainerForm({
  children, title, queryInfo,
}: any) {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const { search } = useLocation();
  const location = useLocation();

  const firstArgsInUrl = location.pathname?.split('/')[2];

  useEffect(() => {
    if (firstArgsInUrl === 'info') setValue(0);
    if (firstArgsInUrl === 'renovation') setValue(1);
    if (firstArgsInUrl === 'simulation') setValue(2);
    if (firstArgsInUrl === 'documents') setValue(3);
  }, [firstArgsInUrl]);

  const steps = [{
    label: 'Informations',
    first: 'info',
    url: '/form/info',
    index: 0,
  }, {
    label: 'Travaux',
    first: 'renovation',
    url: '/form/renovation',
    index: 1,
  }, {
    label: 'Simulation',
    first: 'simulation',
    url: '/form/simulation',
    index: 2,
  }, {
    label: 'Documents',
    first: 'documents',
    url: '/form/documents',
    index: 3,
  }];
  const defaultIndex = steps?.find((f, i) => f.first === firstArgsInUrl)?.index;

  const [index, setIndex] = useState(Number(defaultIndex));

  const indexMax = steps.length - 1;

  const ArrowForward = () => {
    if (index < indexMax) {
      setIndex(index + 1);
      navigate(steps[index + 1].url + search);
    }
  };

  const ArrowBack = () => {
    if (index !== 0) {
      setIndex(index - 1);
      navigate(steps[index - 1].url + search);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      width={{ xs: '95%', md: '80%' }}
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      paddingTop="10px"
      paddingBottom={10}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Box display={{ xs: 'none', sm: 'flex' }} justifyContent="center" width="100%" marginBottom={3}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            scrollButtons="auto"
            sx={{
              width: '60%',
            }}
          >
            {steps.map((e, i) => (
              <Tab
                key={e?.url + i.toString()}
                label={e.label}
                onClick={() => navigate(e.url + search)}
                sx={{
                  color: 'text.primary',
                  textTransform: 'none',
                  letterSpacing: 1,
                  fontWeight: 'bold',
                  '&.Mui-selected': {
                    fontWeight: '500',
                  },
                }}
              />
            ))}
          </Tabs>
        </Box>
        <Box display={{ xs: 'flex', sm: 'none' }} width="100%" paddingY={1.5} justifyContent="space-between" alignItems="center" marginBottom={3}>
          <Box
            color={index !== 0 ? 'secondary.main' : 'background.default'}
            display="flex"
            width="20%"
            alignItems="center"
            justifyContent="center"
            onClick={() => ArrowBack()}
          >
            <ArrowBackIosIcon />
          </Box>
          <Box width="60%" display="flex" alignItems="center" justifyContent="center">
            <Typography fontWeight="300" textAlign="center">{steps[index].label}</Typography>
          </Box>
          <Box
            color={index < indexMax ? 'secondary.main' : 'background.default'}
            display="flex"
            width="20%"
            alignItems="center"
            justifyContent="center"
            onClick={() => ArrowForward()}
          >
            <ArrowForwardIosIcon />
          </Box>
        </Box>
        <Box paddingX={1} display="flex" justifyContent={{ xs: 'space-between', md: 'inherit' }} alignItems="center">
          <Typography
            variant="h6"
            color="primary"
            fontWeight="bold"
            textAlign={{ xs: 'center', md: 'left' }}
            marginRight={{ xs: 0, md: 1 }}
          >
            {title}
          </Typography>
          <Chip
            sx={{ margin: 0.5, fontWeight: 'bold', fontSize: 12 }}
            size="small"
            label={propertyStatusOptions.find((f) => f.value === queryInfo?.data?.getPropertiesById?.status)?.label}
            variant="outlined"
          />
        </Box>
      </Box>
      <Box width="100%" marginY={2}>
        {children}
      </Box>
      <Paper
        elevation={1}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: { xs: 'flex-end', md: 'center' },
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          bgcolor: 'background.paper',
          borderRadius: { xs: '5px !important', md: '10px !important' },
        }}
      />
    </Box>
  );
}

function SectionTitle({ text, subText }: any) {
  return (
    <Box marginBottom={3} display="flex" flexDirection="column">
      <Typography variant="h6" color="text.primary" fontWeight="200">{text}</Typography>
      <Typography fontStyle="italic" fontWeight="bold" variant="caption" marginTop={{ xs: 2, md: 0 }}>
        {subText}
      </Typography>
    </Box>
  );
}

function AccordionContainer({
  children,
  title,
  index,
  indexAccordion,
  setIndexAccordion,
  ifDisplayStage = false,
  fields,
  remove,
  toModifyArrangement,
  setToModifyArrangement,
  append,
  defaultValue,
}: any) {
  const { ipadSize } = useDeviceSize();
  const checkAccordion = () => {
    if (index === indexAccordion) setIndexAccordion(null);
    if (index !== indexAccordion) setIndexAccordion(index);
  };

  return (
    <Accordion
      expanded={index === indexAccordion}
      onChange={checkAccordion}
      sx={{
        width: '100%',
        border: 'solid 0.1px black',
        borderRadius: '5px !important',
        display: 'flex',
        boxShadow: 'none',
        padding: 0,
        paddingY: { xs: 1, md: 0 },
        flexDirection: 'column',
        marginBottom: 3,
        ':before': {
          content: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={{
          paddingRight: { xs: 2, md: 7 },
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          paddingLeft={{ xs: 0, md: 3 }}
          variant="h5"
          color="text.primary"
          fontWeight="bold"
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
      {ifDisplayStage
        && (
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
          }}
          p={2}
        >
          {!toModifyArrangement ? (
            <Button
              onClick={() => setToModifyArrangement(true)}
              sx={{ fontWeight: 'bold', textTransform: 'none' }}
              variant="outlined"
              endIcon={<EditIcon />}
            >
              Ajouter ou supprimer un étage
            </Button>
          ) : (
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box display="flex" justifyContent="center" alignItems="center">
                <ButtonGroup
                  sx={{ marginLeft: 1, display: fields?.length <= 1 ? 'none' : 'inherit' }}
                  color="error"
                  orientation={ipadSize ? 'horizontal' : 'vertical'}
                >
                  {fields?.map((e: any, i: any) => (
                    <Button
                      key={e?.id}
                      disabled={i === 0}
                      onClick={() => remove(i)}
                      endIcon={i > 0 && <DeleteForeverIcon />}
                    >
                      {i === 0 ? 'Suprimer' : `n°${i}`}
                    </Button>
                  ))}
                </ButtonGroup>
              </Box>
              <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent={{ xs: 'space-between', sm: 'inherit' }}>
                <Button
                  sx={{
                    marginRight: { xs: 'inherit', sm: '10px' },
                    fontWeight: 'bold',
                    textTransform: 'none',
                  }}
                  onClick={() => setToModifyArrangement(false)}
                  color="error"
                  type="reset"
                  variant="outlined"
                  endIcon={<CancelIcon />}
                >
                  Annuler
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                  endIcon={<AddCircleIcon />}
                  color="primary"
                  onClick={() => append(defaultValue)}
                >
                  Ajouter un étage
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        )}
    </Accordion>
  );
}

function GridContainerFormInput({ children, xsCol = 2, smCol = 4 }: any) {
  return (
    <Grid
      container
      columns={{ xs: xsCol, sm: smCol }}
      display="flex"
      padding={{ xs: 'inherit', md: '10px 24px' }}
      spacing={{ xs: 0, md: 7 }}
      width="auto"
    >
      {children}
    </Grid>
  );
}

function SubmitButtonForm({ index, indexMax }: any) {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Button
        variant="contained"
        sx={{ textTransform: 'none', color: 'text.secondary' }}
        type="submit"
        color="primary"
        endIcon={index === indexMax - 1 ? <ArrowForwardIosIcon /> : null}
      >
        {index === indexMax - 1 ? 'Valider et passer à l\'étape suivante' : 'Enregistrer'}
      </Button>
    </Box>
  );
}

export {
  ContainerForm,
  SectionTitle,
  AccordionContainer,
  SubmitButtonForm,
  GridContainerFormInput,
};
