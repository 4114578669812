import { useInfiniteGetAllPropertiesByUserQuery } from '../../api/infinite_scroll_index';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useInfiniteGetAllPropertiesByUser = (variables: any, rowPerPageOptions: any) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();

  const {
    data,
    isFetching,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteGetAllPropertiesByUserQuery<any>(
    headerWithAuth,
    'args',
    variables,
    {
      // @ts-ignore
      enabled: !!accessToken,
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => {
        if (lastPage?.getAllPropertiesByUser?.page?.pageInfo.hasNextPage) {
          return {
            args: {
              first: rowPerPageOptions?.value,
              after: lastPage?.getAllPropertiesByUser?.page?.pageInfo?.endCursor,
            },
          };
        }
        return null;
      },
    },
  );
  return {
    data,
    isFetching,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  };
};

export {
  useInfiniteGetAllPropertiesByUser,
};
