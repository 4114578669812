import { useQuery } from '@tanstack/react-query';
import { useGetAccessToken } from '../auth/get-access-token.hook';

function useGlobalConfig() {
  const { accessToken } = useGetAccessToken();

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${accessToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return {
    getGlobalConfig: useQuery(
      ['getGlobalConfig'],
      () => fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/global-config/config`, requestOptions)
        .then((response) => response.json()),
    ),
  };
}

export {
  useGlobalConfig,
};
