import React, { useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { CurrencyTextFieldCustom } from '../../../common/inputs/currencytextfield';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import { useUpdateProperty } from '../../../hooks/mutate/update-property-mutation.hook';
import { AccordionContainer, GridContainerFormInput, SubmitButtonForm } from '../../components/containerform';

interface IFormInput {
  furniture_cost: string
  renovation_cost: string
  renovationDuration: number
}

function RenovationItem({
  indexAccordion, setIndexAccordion, index, indexMax, title, data, queryInfo,
}:any) {
  const [searchParams] = useSearchParams();
  const {
    control, handleSubmit, reset,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return {
        ...data,
      };
    }, [data]),
  });

  useEffect(() => {
    reset({
      ...data,
    });
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
      // eslint-disable-next-line no-param-reassign
      setIndexAccordion(indexAccordion += 1);
      // eslint-disable-next-line no-param-reassign
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        renovation: {
          furniture_cost: Number(form?.furniture_cost),
          renovation_cost: Number(form?.renovation_cost),
          renovationDuration: Number(form?.renovationDuration),
        },
      },
    };
    mutate({ ...formValid });
  };

  return (
    <AccordionContainer
      title={title}
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
      index={index}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box paddingX={{ xs: 0, md: 7 }}>
          <Box>
            <GridContainerFormInput
              xsCol={1}
              smCol={2}
            >
              <Grid item xs={2}>
                <CurrencyTextFieldCustom
                  control={control}
                  name="renovation_cost"
                  label="Prix des travaux"
                />
                <CurrencyTextFieldCustom
                  control={control}
                  name="furniture_cost"
                  label="Prix des meubles"
                />
                <TextFieldCustom
                  type="number"
                  control={control}
                  name="renovationDuration"
                  label="Durée des travaux (mois)"
                />
              </Grid>
            </GridContainerFormInput>
            <SubmitButtonForm index={index} indexMax={indexMax} />
          </Box>
        </Box>
      </Box>
    </AccordionContainer>
  );
}

export {
  RenovationItem,
};
