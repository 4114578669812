import {
  alpha, Box, Chip, Divider, Grid, Slider, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import icon_1 from '../../../assets/simulation/icon_1.png';
import icon_2 from '../../../assets/simulation/icon_2.png';
import icon_3 from '../../../assets/simulation/icon_3.png';
import icon_4 from '../../../assets/simulation/icon_4.png';
import icon_5 from '../../../assets/simulation/icon_5.png';
import icon_7 from '../../../assets/simulation/icon_7.png';
import { euro } from '../../../common/inputs/format/euro-format';
import apport_white from '../../../assets/simulation/apport_white.png';
import duree_white from '../../../assets/simulation/duree_white.png';
import tri from '../../../assets/simulation/tri.png';
import cash_flow from '../../../assets/simulation/cash_flow.png';
import calendrier from '../../../assets/simulation/calendrier.png';

function CashFLow({ queryInfo, simulationColor, getGlobalConfig }: any) {
  const data = queryInfo?.data?.getPropertiesById?.finance?.property_cash_flow;

  const [firstSliderValue, setFirstSliderValue] = useState<any>(15);
  const dataFinance = queryInfo && queryInfo?.data?.getPropertiesById?.finance;
  const totalBudget = queryInfo && queryInfo?.data?.getPropertiesById?.finance?.total_budget;

  const [years, setYears] = useState<any>(getGlobalConfig?.data?.number_of_years_loan);
  const [interestRateTmp, setInterestRateTmp] = useState<any>(getGlobalConfig?.data?.interest_rate * 100);
  const [cashFlowTmp, setCashFlowTmp] = useState<any>(dataFinance && dataFinance?.property_cash_flow?.cash_flow ? dataFinance?.property_cash_flow?.cash_flow : 0);
  const [monthlyLoanBudgetTmp, setMonthlyLoanBudgetTmp] = useState<any>(dataFinance && dataFinance?.property_cash_flow?.monthly_loan_amount ? dataFinance?.property_cash_flow?.monthly_loan_amount : 0);
  const [equityContributionTmp, setEquityContributionTmp] = useState<any>(dataFinance && totalBudget ? 15 * totalBudget / 100 : 0);
  const [monthlyOutcomeTmp, setMonthlyOutcomeTmp] = useState<any>(dataFinance && dataFinance?.property_cash_flow?.monthly_outcome ? dataFinance?.property_cash_flow?.monthly_outcome : 0);
  const [loanBudgetTmp, setLoanBudgetTmp] = useState<any>(totalBudget && equityContributionTmp ? totalBudget - equityContributionTmp : 0);

  useEffect(() => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    setEquityContributionTmp(firstSliderValue * totalBudget / 100);
  }, [firstSliderValue, totalBudget]);

  useEffect(() => {
    const montlhyIncome = dataFinance?.property_cash_flow?.monthly_income;
    // eslint-disable-next-line no-unsafe-optional-chaining
    const monthlyOutcome = (monthlyLoanBudgetTmp + dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees + dataFinance?.property_cash_flow?.monthly_insurance_fees + dataFinance?.property_cash_flow?.monthly_property_tax + dataFinance?.property_cash_flow?.monthly_landlord_fees);
    setMonthlyOutcomeTmp(monthlyOutcome);
    setCashFlowTmp(montlhyIncome - monthlyOutcome);
  }, [dataFinance, monthlyLoanBudgetTmp]);

  useEffect(() => {
    setLoanBudgetTmp(totalBudget - equityContributionTmp);
    if (years === getGlobalConfig?.data?.number_of_years_loan_15) {
      setInterestRateTmp(getGlobalConfig?.data?.interest_rate_15 * 100);
    }
    if (years === getGlobalConfig?.data?.number_of_years_loan_20) {
      setInterestRateTmp(getGlobalConfig?.data?.interest_rate_20 * 100);
    }
    if (years === getGlobalConfig?.data?.number_of_years_loan) {
      setInterestRateTmp(getGlobalConfig?.data?.interest_rate * 100);
    }
    setMonthlyLoanBudgetTmp(((interestRateTmp / 100) * loanBudgetTmp) / ((1 - (1 + (interestRateTmp / 100) / 12) ** (-12 * years)) * 12));
  }, [equityContributionTmp, interestRateTmp, loanBudgetTmp, totalBudget, years]);

  const dataSmallBar = [{
    title: 'Mensualités emprunt',
    value: monthlyLoanBudgetTmp,
    icon: icon_2,
  }, {
    title: 'Gestion locative',
    value: data?.monthly_real_estate_agency_fees,
    icon: icon_4,
  }, {
    title: 'Assurances',
    value: data?.monthly_insurance_fees,
    icon: icon_3,
  }, {
    title: 'Taxes foncières mensualisées',
    value: data?.monthly_property_tax,
    icon: icon_1,
  }, {
    title: 'Charges de copropriété',
    value: data?.monthly_landlord_fees,
    icon: icon_7,
  }];

  return (
    <Box marginY={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
      <Box
        display="flex"
        flexDirection="column"
        width={{ xs: '100%', sm: '80%' }}
      >
        <Typography
          fontWeight="200"
          gutterBottom
          sx={{
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Ajustez l’apport et la durée du prêt pour faire varier votre cashflow
        </Typography>
        <SliderComponent
          title="Apport"
          titleValue={euro.format(equityContributionTmp)}
          sliderValue={firstSliderValue}
          setSliderValue={setFirstSliderValue}
          icon={apport_white}
          min={0}
          max={100}
          step={1}
          displayValue
        />
        <SliderComponent
          title="Durée"
          titleValue={`${years} ans`}
          sliderValue={years}
          setSliderValue={setYears}
          icon={duree_white}
          min={15}
          max={25}
          step={5}
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          marginY={2}
          sx={{
            flexWrap: { xs: 'wrap', md: 'inherit' },
          }}
        >
          <GreenCircle
            color={simulationColor}
            title="Taux estimé"
            icon={tri}
            value={interestRateTmp ? `${interestRateTmp.toFixed(2)}%` : `${0}%`}
          />
          <GreenCircle
            color={simulationColor}
            title="Cash flow"
            icon={cash_flow}
            value={cashFlowTmp ? euro?.format(cashFlowTmp) : `${0} €`}
          />
          <GreenCircle
            color={simulationColor}
            title="Mensualité"
            icon={calendrier}
            value={monthlyLoanBudgetTmp ? euro.format(monthlyLoanBudgetTmp) : `${0} €`}
          />
        </Box>
        <Divider sx={{ marginY: 5 }} />
      </Box>
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        flexDirection="column"
        width={{ xs: '100%', sm: '80%' }}
      >
        <LargeBar
          color={simulationColor.green}
          title="Encaissements"
          value={data?.monthly_income}
          Icon={<ArrowDownwardIcon sx={{ color: 'background.paper', fontSize: { xs: '20px', sm: '40px' } }} />}
        />
        <Box
          width={{ xs: '100%', sm: '90%' }}
          sx={{
            borderRadius: '5px',
            border: '0.1px solid',
            borderColor: alpha('#000000', 0.1),
          }}
          display="flex"
          marginBottom={5}
          flexDirection="column"
        >
          <SmallBar
            title="Loyers"
            color={simulationColor.red}
            value={data?.monthly_income}
            icon={icon_5}
          />
        </Box>
        <LargeBar
          title="Décaissements"
          color={simulationColor.red}
          value={monthlyOutcomeTmp}
          Icon={<ArrowUpwardIcon sx={{ color: 'background.paper', fontSize: { xs: '20px', sm: '40px' } }} />}
        />
        <Box
          width={{ xs: '100%', sm: '90%' }}
          sx={{
            borderRadius: '5px',
            border: '0.1px solid',
            borderColor: alpha('#000000', 0.1),
          }}
          display="flex"
          marginBottom={5}
          flexDirection="column"
        >
          {dataSmallBar?.map((e, i) => (
            <SmallBar
              key={e.title + i.toString()}
              title={e?.title}
              icon={e.icon}
              bar={i + 1 !== dataSmallBar?.length}
              color={simulationColor.red}
              value={e?.value}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function SliderComponent({
  sliderValue, setSliderValue, title, titleValue, icon, min, max, step, displayValue = false,
}: any) {
  return (
    <Box>
      <Grid
        container
        columns={2}
        display="flex"
        width="auto"
        height="40px"
        marginTop={4}
      >
        <Grid item xs={1} display="flex" alignItems="center">
          <Box
            width="35px"
            height="35px"
            borderRadius={30}
            bgcolor="text.primary"
            marginRight={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              src={icon}
              width="25px"
              sx={{ objectFit: 'cover', display: 'block' }}
            />
          </Box>
          <Typography fontWeight="400" variant="h6" marginRight={1}>
            {title}
          </Typography>
          {displayValue
            && (
              <Typography color="primary" fontWeight="400" variant="h6">
                {`${sliderValue}%`}
              </Typography>
            )}
        </Grid>
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
          <Typography
            textAlign="right"
            variant="h6"
            color="primary"
          >
            {titleValue}
          </Typography>
        </Grid>
      </Grid>
      <Box height="60px">
        <Slider
          color="secondary"
          sx={{
            height: 5,
          }}
          valueLabelDisplay="auto"
          step={step}
          value={sliderValue}
          min={min}
          onChange={(e: any, value: any) => {
            setSliderValue(value);
          }}
          max={max}
        />
      </Box>
    </Box>
  );
}

function GreenCircle({
  color, title, icon, value,
}: any) {
  return (
    <Box
      borderRadius={100}
      bgcolor={color?.blueLightBar}
      display="flex"
      marginX={1}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        width: { xs: '130px', md: '170px' },
        height: { xs: '130px', md: '170px' },
        marginY: { xs: 1, md: 0 },
      }}
    >
      <Typography marginBottom={0.5} variant="body1" fontWeight="500">{title}</Typography>
      <Box
        component="img"
        src={icon}
        sx={{
          objectFit: 'cover',
          display: 'block',
          width: { xs: '30px', md: '40px' },
        }}
      />
      <Typography marginTop={0.5} variant="h6" fontWeight="500">{value}</Typography>
    </Box>
  );
}

function LargeBar({
  title, color, value, Icon,
}: any) {
  return (
    <Box
      width="100%"
      height="70px"
      sx={{
        borderRadius: '5px',
        border: '0.1px solid',
        borderColor: alpha('#000000', 0.1),
      }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginBottom={3}
    >
      <Box display="flex" alignItems="center" paddingX={3}>
        <Box
          width={40}
          height={40}
          bgcolor={color}
          borderRadius={30}
          marginRight={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {Icon}
        </Box>
        <Typography
          fontWeight={500}
          variant="h6"
        >
          {title}
        </Typography>
      </Box>
      <Typography
        fontWeight={500}
        paddingX={3}
        color={color}
        variant="h6"
      >
        {euro.format(value)}
      </Typography>
    </Box>
  );
}

function SmallBar({
  title, color, value, bar, icon, chip = false,
}: any) {
  return (
    <>
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" marginY={1}>
        <Box display="flex" alignItems="center" paddingX={3} height={{ xs: 50, sm: 'inherit' }}>
          <Box
            width={{ xs: 30, sm: 40 }}
            height={{ xs: 30, sm: 40 }}
            borderRadius={30}
            border="1px solid"
            borderColor="divider"
            marginRight={3}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              src={icon}
              sx={{
                display: 'block',
                objectFit: 'cover',
                width: '90%',
                height: '90%',
              }}
            />
          </Box>
          <Typography
            fontWeight={400}
            variant="body1"
            fontSize={{ xs: '14px', sm: 'inherit' }}
          >
            {title}
          </Typography>
        </Box>
        {chip && (
          <Chip
            size="small"
            label="Optionnel"
          />
        )}
        <Typography
          fontWeight={500}
          paddingX={3}
          color={color}
          variant="h6"
          fontSize={{ xs: '16px', sm: 'inherit' }}
        >
          {euro.format(value)}
        </Typography>
      </Box>
      {bar && <Box width="75%" margin="0 auto" height="1px" bgcolor="primary.main" />}
    </>
  );
}

export {
  CashFLow,
};
