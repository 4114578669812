import React from 'react';
import NumberFormat from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

// @ts-ignore
const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        thousandSeparator=" "
        fixedDecimalScale
        decimalSeparator=","
        decimalScale={2}
        suffix=" €"
      />
    );
  },
);

export {
  NumberFormatCustom,
};
