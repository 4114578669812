import React from 'react';
import {
  Box, Grid, IconButton, Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function AddOrRemoveField({
  control, name, label, watch, icon,
}: any) {
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({
          field,
        }) => {
          return (
            <Box>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                onClick={() => field.onChange(field.value > 9 ? field.value : field.value + 1)}
                sx={{
                  opacity: watch(name) === 0 ? 0.5 : 1,
                  transition: 'all 0.3s',
                  cursor: 'pointer',
                }}
              >
                <Box
                  component="img"
                  src={icon}
                  height={60}
                  sx={{ objectFit: 'cover' }}
                />
                <Typography marginTop={1}>{label}</Typography>
              </Box>
              <Grid item height={50}>
                <Box display={watch(name) === 0 ? 'none' : 'flex'} alignItems="center" justifyContent="center">
                  <IconButton
                    disabled={field.value < 1}
                    onClick={() => field.onChange(field.value - 1)}
                    color="primary"
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                  <Typography marginX={3}>{field.value}</Typography>
                  <IconButton
                    disabled={field.value > 9}
                    onClick={() => field.onChange(field.value > 9 ? field.value : field.value + 1)}
                    color="primary"
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Box>
          );
        }}
      />
    </Box>
  );
}

export {
  AddOrRemoveField,
};
