import React from 'react';
import { ContainerTypeOfProperty } from '../containers/container-type-of-property';

function TypeOfProperty() {
  return (
    <ContainerTypeOfProperty />
  );
}

export {
  TypeOfProperty,
};
