import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  AccordionContainer,
  GridContainerFormInput,
  SectionTitle,
  SubmitButtonForm,
} from '../../components/containerform';
import { AutocompleteCustom } from '../../../common/inputs/autocompletecustom';
import { levelOfUrgencyOptions, offersOptions, sellingReasonOptions } from '../../../data/data';
import { RichTextCustom } from '../../../common/inputs/richtextcustom';
import { AllowedOffer } from '../../../api';
import { CurrencyTextFieldCustom } from '../../../common/inputs/currencytextfield';
import { Agency } from './agency';
import { useGetAllRealEstateAgencies } from '../../../hooks/query/get-all-real-estate-agencies-query.hook';
import { useGetAgencyById } from '../../../hooks/query/get-agency-by-id-query.hook';
import { useUpdateProperty } from '../../../hooks/mutate/update-property-mutation.hook';

interface IFormInput {
  selling_reason: string
  level_of_urgency: string
  comment: string
  property_offer: string
  expected_property_selling_price: string,
  realEstateAgency: string | null,
  realEstateAgent: string | null,
}

function Conclusion({
  indexAccordion, setIndexAccordion, index, indexMax, title, data, queryInfo,
}: any) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [openAddAgency, setOpenAddAgency] = useState(false);
  const [openAddAgent, setOpenAddAgent] = useState(false);
  const [agencyId, setAgencyId] = useState(queryInfo?.data?.getPropertiesById?.realEstateAgency?._id);

  const queryAgency = useGetAllRealEstateAgencies();
  const queryAgent = useGetAgencyById(agencyId);

  const format = {
    ...data,
    selling_reason: sellingReasonOptions.find((f) => f.value === data?.selling_reason),
    level_of_urgency: levelOfUrgencyOptions.find((f) => f.value === data?.level_of_urgency),
    property_offer: offersOptions.find((f) => f.value === data?.property_offer),
    realEstateAgency: queryInfo?.data?.getPropertiesById?.realEstateAgency?._id ? {
      value: queryInfo?.data?.getPropertiesById?.realEstateAgency?._id,
      label: `${queryInfo?.data?.getPropertiesById?.realEstateAgency?.realEstateAgencyName} - ${queryInfo?.data?.getPropertiesById?.realEstateAgency?.realEstateAgencyAddress}`,
    } : null,
    realEstateAgent: {
      value: queryInfo?.data?.getPropertiesById?.realEstateAgent?._id,
      label: queryInfo?.data?.getPropertiesById?.realEstateAgent?.name,
    },
  };

  const {
    control, handleSubmit, reset, watch, setValue,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return { ...format };
    }, [format]),
  });

  useEffect(() => {
    // @ts-ignore
    setAgencyId(watch('realEstateAgency')?.value);
  }, [watch('realEstateAgency')]);

  useEffect(() => {
    reset({ ...format });
  }, [queryInfo.isSuccess]);

  const mutate = useUpdateProperty({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
      // eslint-disable-next-line no-param-reassign
      setIndexAccordion(indexAccordion += 1);
      // eslint-disable-next-line no-param-reassign
      navigate(`/form/renovation${search}`);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        realEstateAgency: form.realEstateAgency?.value,
        realEstateAgent: form?.realEstateAgent?.value,
        conclusion: {
          selling_reason: form?.selling_reason?.value,
          level_of_urgency: form?.level_of_urgency?.value,
          comment: form?.comment,
          property_offer: form?.property_offer?.value,
          expected_property_selling_price: Number(form?.expected_property_selling_price),
        },
      },
    };

    Object.keys(formValid.data.conclusion).forEach((key) => {
      if (formValid.data.conclusion[key] === undefined || formValid.data.conclusion[key] === null || Number.isNaN(formValid.data.conclusion[key])) {
        delete formValid.data.conclusion[key];
      }
    });
    mutate({ ...formValid });
  };

  const tabAgency = queryAgency?.data?.getAllRealEstateAgencies?.map((e: any) => ({
    value: e?._id,
    label: `${e?.realEstateAgencyName} - ${e?.realEstateAgencyAddress}`,
  }));

  const tabAgent = queryAgent?.data?.getAgencyById?.realEstateAgents?.map((e: any) => ({
    value: e?._id,
    label: e?.name,
  }));

  return (
    <AccordionContainer
      title={title}
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
      index={index}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box paddingX={{ xs: 0, md: 7 }}>
          <Box>
            <SectionTitle text="Informations importantes pour la négociation" />
            <GridContainerFormInput
              xsCol={1}
              smCol={1}
            >
              <Grid item xs={2}>
                <AutocompleteCustom
                  control={control}
                  tab={sellingReasonOptions}
                  name="selling_reason"
                  label="Raison de la vente"
                />
                <AutocompleteCustom
                  control={control}
                  tab={levelOfUrgencyOptions}
                  name="level_of_urgency"
                  label="Niveau d’urgence"
                />
                <AutocompleteCustom
                  control={control}
                  tab={offersOptions}
                  name="property_offer"
                  label="Offre"
                />
                {
                  // @ts-ignore
                  watch('property_offer')?.value === AllowedOffer.ETOILE
                  && (
                    <CurrencyTextFieldCustom
                      control={control}
                      name="expected_property_selling_price"
                      label="Prix de revente estimé"
                    />
                  )
                }
                {queryInfo
                  && (
                    <Agency
                      tabAgency={tabAgency}
                      tabAgent={tabAgent}
                      control={control}
                      openAddAgency={openAddAgency}
                      setOpenAddAgency={setOpenAddAgency}
                      setOpenAddAgent={setOpenAddAgent}
                      queryAgency={queryAgency}
                      setValue={setValue}
                      openAddAgent={openAddAgent}
                      watch={watch}
                      queryInfo={queryInfo}
                    />
                  )}
                <SectionTitle text="Ajouter un commentaire" />
                <RichTextCustom
                  name="comment"
                  control={control}
                />
              </Grid>
            </GridContainerFormInput>
            <Box marginBottom={2} />
            <SubmitButtonForm index={index} indexMax={indexMax} />
          </Box>
        </Box>
      </Box>
    </AccordionContainer>
  );
}

export {
  Conclusion,
};
