import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box, Grid, styled, TextField, Typography,
} from '@mui/material';

function TextFieldCustom({
  control,
  name,
  label,
  type = 'text',
  rules = null,
  size = 'small',
  variant = 'outlined',
  numberMin = 0,
  numberMax,
  height = 80,
  required = false,
  placeholder = 'Écrire ici ...',
}: any) {
  return (
    <Grid item height={height}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field, fieldState,
        }) => {
          return (
            <Box>
              <CssTextField
                {...field}
                type={type}
                required={required}
                label={label}
                placeholder={placeholder}
                size={size}
                value={type === 'number' ? field.value?.toString() || '' : field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
                onBlur={field.onBlur}
                inputProps={{
                  min: numberMin,
                  max: numberMax,
                }}
                fullWidth
                variant={variant}
              />
              {fieldState?.error?.message
              && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
            </Box>
          );
        }}
      />
    </Grid>
  );
}

const CssTextField = styled(TextField)(({ theme }) => ({
  label: {
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
  input: {
    color: theme.palette.text.primary,
  },
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '.css-ke91x2-MuiInputBase-root-MuiInput-root:before': {
    borderBottomColor: theme.palette.primary.main,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.primary.main,
  },
}));

export {
  TextFieldCustom,
};
