import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { AddAgencyContainer, AddAgentContainer } from '../../../common/agency/add-input';
import { AutocompleteCustom } from '../../../common/inputs/autocompletecustom';

function Agency({
  tabAgency,
  tabAgent,
  control,
  openAddAgency,
  setOpenAddAgency,
  setOpenAddAgent,
  queryAgency,
  setValue,
  watch,
  openAddAgent,
  queryInfo,
}: any) {
  useEffect(() => {
    // @ts-ignore
    if (watch('realEstateAgency')?.value !== queryInfo?.data?.getPropertiesById?.realEstateAgency?._id) {
      setValue('realEstateAgent', null);
    }
  }, [watch('realEstateAgency')]);
  return (
    <>
      {!openAddAgency && tabAgency
        && (
          <>
            <AutocompleteCustom
              size="small"
              tab={tabAgency && tabAgency}
              control={control}
              name="realEstateAgency"
              label="Veuillez sélectionner une agence immobilière"
            />
            <Button
              type="button"
              sx={{
                marginTop: -4,
                marginBottom: 2,
                cursor: 'pointer',
                textTransform: 'none',
                textDecoration: 'underline',
                transition: 'opacity 0.3s',
                width: 'fit-content',
                fontSize: '16px',
                ':hover': {
                  opacity: 0.8,
                },
              }}
              startIcon={<AddCircleIcon />}
              color="primary"
              onClick={() => {
                setOpenAddAgency(!openAddAgency);
                setOpenAddAgent(false);
              }}
            >
              Ajouter une agence immobilière
            </Button>
          </>
        )}
      {openAddAgency && (
      <AddAgencyContainer
        queryAgency={queryAgency}
        openAddAgency={openAddAgency}
        setOpenAddAgency={setOpenAddAgency}
        setValue={setValue}
      />
      )}
      {watch('realEstateAgency') && !openAddAgent
        && (
          <>
            <AutocompleteCustom
              size="small"
              tab={tabAgent || []}
              control={control}
              name="realEstateAgent"
              label="Veuillez sélectionner un agent immobilier"
            />
            <Button
              type="button"
              sx={{
                marginTop: -4,
                cursor: 'pointer',
                textTransform: 'none',
                textDecoration: 'underline',
                transition: 'opacity 0.3s',
                fontSize: '16px',
                width: 'fit-content',
                ':hover': {
                  opacity: 0.8,
                },
              }}
              startIcon={<AddCircleIcon />}
              color="primary"
              onClick={() => {
                setOpenAddAgent(!openAddAgent);
                setOpenAddAgency(false);
              }}
            >
              Ajouter un agent immobilier
            </Button>
          </>
        )}
      {openAddAgent && (
      <AddAgentContainer
        setOpen={setOpenAddAgent}
        agencyId={watch('realEstateAgency')?.value}
        queryAgency={queryAgency}
        setValue={setValue}
      />
      )}
      {queryInfo?.data?.getPropertiesById?.realEstateAgent?._id
        && watch('realEstateAgent')?.value === queryInfo?.data?.getPropertiesById?.realEstateAgent?._id
        && watch('realEstateAgency')?.value === queryInfo?.data?.getPropertiesById?.realEstateAgency?._id
        && (
          <Box marginY={3}>
            <Box display="flex" alignItems="center">
              <AccountCircleIcon color="primary" fontSize="small" />
              <Typography variant="body2" fontWeight="bold" marginLeft={1}>
                {queryInfo?.data?.getPropertiesById?.realEstateAgent?.name}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginY={1}>
              <AlternateEmailIcon color="primary" fontSize="small" />
              <Box
                component="a"
                sx={{ color: 'inherit', textDecoration: 'none' }}
                href={`mailto:${queryInfo?.data?.getPropertiesById?.realEstateAgent?.email}`}
              >
                <Typography variant="body2" fontWeight="bold" marginLeft={1}>
                  {queryInfo?.data?.getPropertiesById?.realEstateAgent?.email}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <LocalPhoneIcon color="primary" fontSize="small" />
              <Box
                component="a"
                sx={{ color: 'inherit', textDecoration: 'none' }}
                href={`tel:${queryInfo?.data?.getPropertiesById?.realEstateAgent?.phone}`}
              >
                <Typography variant="body2" fontWeight="bold" marginLeft={1} letterSpacing={1}>
                  {queryInfo?.data?.getPropertiesById?.realEstateAgent?.phone ? queryInfo?.data?.getPropertiesById?.realEstateAgent?.phone : ''}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
    </>
  );
}

export {
  Agency,
};
