import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function useAccessToken() {
  const { getAccessTokenSilently } = useAuth0();
  const memo = useCallback(
    async () => {
      // eslint-disable-next-line no-return-await
      return await getAccessTokenSilently();
    },
    [getAccessTokenSilently],
  );
  return { getToken: memo };
}

function useGetAccessToken() {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { getToken } = useAccessToken();

  const headerWithAuth = {
    endpoint: `${process.env.REACT_APP_SERVER_BASE_URL}/graphql`,
    fetchParams: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  };

  useEffect(() => {
    getToken().then((token: string | null) => {
      setAccessToken(token);
    });
  }, [getToken]);

  return { accessToken, headerWithAuth };
}

export {
  useGetAccessToken,
};
