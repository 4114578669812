import React, { useEffect, useState } from 'react';
import {
  Box, Button, Chip, CircularProgress, styled, Typography,
} from '@mui/material';
import TimerIcon from '@mui/icons-material/Timer';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { useNavigate } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ButtonCustom } from '../../common/inputs/buttoncustom';
import {
  flatTypeOptions, levelOfUrgencyOptions, propertyStatusOptions,
} from '../../data/data';
import { FilterPropertyList } from './filter-list';
import { useInfiniteGetAllPropertiesByUser } from '../../hooks/query/infinite-get-all-properties-by-user-query.hook';
import { euro } from '../../common/inputs/format/euro-format';
import { useDeviceSize } from '../../hooks/utils/device-size.hook';

function DisplayProperties() {
  const [pageIndex, setPageIndex] = useState(0);
  const [rowPerPageOptions, setRowPerPageOptions] = useState<{ value: number, label: string }>({
    value: 10,
    label: '10',
  });
  const [queryParamsValue, setQueryParamsValue] = useState({});
  const freshFilter = Object?.fromEntries(Object?.entries(queryParamsValue)?.filter(([_, v]) => {
    // @ts-ignore
    return v !== null && v?.length !== 0;
  }));

  useEffect(() => {
    setPageIndex(0);
  }, [rowPerPageOptions]);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
    });
  }, [pageIndex]);

  const {
    data,
    isFetching,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteGetAllPropertiesByUser({
    args: { first: rowPerPageOptions?.value },
    filters: {
      ...freshFilter,
    },
  }, rowPerPageOptions);

  const numberProperties = data?.pages[0]?.getAllPropertiesByUser?.pageData?.count || 0;
  const totalPages = data ? Math.trunc(data?.pages[0]?.getAllPropertiesByUser?.pageData?.count / rowPerPageOptions?.value) : 0;

  return (
    <Box
      marginY={{ xs: 0, sm: '50px' }}
      width="90%"
      paddingY={{ xs: 5, sm: 0 }}
      flexDirection="column"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Box
        display="flex"
        width="100%"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box width={{ xs: '100%', sm: 'auto' }} display="flex" alignItems="center" flexDirection="column">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              variant="h4"
              fontWeight="bold"
              width={{ xs: '90%', sm: '100%' }}
              textAlign={{ xs: 'center', sm: 'left' }}
              marginRight={2}
            >
              <Text>
                Liste
              </Text>
              {' '}
              des biens
            </Typography>
            <Chip
              label={`nb: ${numberProperties}`}
              size="small"
              color="primary"
              sx={{
                color: (theme) => theme.palette.background.paper,
              }}
            />
          </Box>
          <Typography
            fontWeight="bold"
            width={{ xs: '90%', sm: '100%' }}
            variant="body1"
            textAlign={{ xs: 'center', sm: 'left' }}
          >
            {'Voici l\'ensemble des biens visités'}
          </Typography>
        </Box>
        <Box marginTop={{ xs: 3, sm: 0 }}>
          <FilterPropertyList queryParamsValue={queryParamsValue} setQueryParamsValue={setQueryParamsValue} />
        </Box>
      </Box>
      {!isFetching && isSuccess
        ? (
          <>
            <MapOnProperties data={data?.pages[pageIndex]?.getAllPropertiesByUser?.page?.edges} />
            <ButtonNavigation
              data={data}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              totalPages={totalPages}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
            />
          </>
        ) : (
          <Box height="calc(100vh - 300px)" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={80} />
          </Box>
        )}
    </Box>
  );
}

function MapOnProperties({ data }: any) {
  const navigate = useNavigate();

  return (
    <Box
      width={{ xs: '100%', sm: '80%' }}
      marginY={4}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      {
        data?.map((e: any) => (
          <Box
            key={e?.node?._id}
            width="100%"
            marginY={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="5px"
            sx={{
              border: '0.1px solid black',
            }}
          >
            <Box bgcolor="background.paper" width="90%" paddingY={3}>
              <Box display="flex" justifyContent="space-between">
                <Chip
                  sx={{ margin: 0.5, fontWeight: 'bold', fontSize: 12 }}
                  size="small"
                  label={propertyStatusOptions?.find((f) => f.value === e?.node?.status)?.label}
                  variant="outlined"
                />
                {e?.node?.conclusion?.level_of_urgency
                  && (
                    <Box display="flex">
                      <TimerIcon sx={{
                        marginRight: 1,
                        color: levelOfUrgencyOptions?.find((f) => f.value === e?.node?.conclusion?.level_of_urgency)?.color,
                      }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          color: levelOfUrgencyOptions?.find((f) => f.value === e?.node?.conclusion?.level_of_urgency)?.color,
                        }}
                        fontWeight="bold"
                      >
                        {levelOfUrgencyOptions?.find((f: any) => f?.value === e?.node?.conclusion?.level_of_urgency)?.label}
                      </Typography>
                    </Box>
                  )}
              </Box>
              <Box marginY={3} />
              <Box
                width="100%"
                display="flex"
                flexDirection={{ xs: 'column-reverse', md: 'row' }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box width={{ xs: '100%', md: '60%' }}>
                  <Typography marginBottom={2} variant="h5" fontWeight="bold">
                    {
                      // @ts-ignore
                      e?.node?.description?.flat_type ? `${flatTypeOptions?.find((f) => f.value === e?.node?.description?.flat_type)?.label} ${e?.node?.description?.surface ? `de ${e?.node?.description?.surface}m2` : ''} ${e?.node?.localisation?.street_address?.locality ? `à ${e?.node?.localisation?.street_address?.locality}` : '' || ''}` : ''
                    }
                  </Typography>
                  <Typography display="flex" marginBottom={1} variant="body1" fontWeight="400" alignItems="center">
                    <LocationOnIcon color="primary" sx={{ marginRight: 1 }} />
                    {e?.node?.localisation?.street_address?.address_line_1}
                  </Typography>
                  {e.node?.ad_link
                    && (
                      <Typography display="flex" marginBottom={1} variant="body1" fontWeight="400" alignItems="center">
                        <LinkIcon color="primary" sx={{ marginRight: 1 }} />
                        <Box
                          component="a"
                          target="_blank"
                          href={e?.node?.ad_link && e?.node?.ad_link[0]}
                          rel="noreferrer"
                          sx={{ color: 'black' }}
                        >
                          {'Lien de l\'annonce'}
                        </Box>
                      </Typography>
                    )}
                  <Typography variant="h5" fontWeight="bold" color="primary" display="flex" alignItems="center">
                    <LocalOfferIcon color="primary" sx={{ marginRight: 1 }} />
                    {`Prix affiché ${e?.node?.description?.upfront_price ? euro.format(e?.node?.description?.upfront_price) : '...'}`}
                  </Typography>
                  <Box display="flex" justifyContent="center" alignItems="flex-end" marginTop={4}>
                    <ButtonCustom
                      onClick={() => navigate(`/form/info?propertyId=${e?.node?._id}`)}
                      margin={0}
                      color="primary"
                      label="Voir le bien"
                    />
                  </Box>
                </Box>
                <Box
                  marginBottom={{ xs: 3, md: 0 }}
                  width={{ xs: '100%', md: '40%' }}
                  bgcolor="divider"
                  display="flex"
                  height={{ xs: '200px', md: '220px' }}
                  alignItems="center"
                  justifyContent="center"
                >
                  {
                    e?.node?.propertyPictureDocuments?.find((f: any) => f?.mimeType?.includes('image'))
                      ? (
                        <Box
                          component="img"
                          src={e?.node?.propertyPictureDocuments?.find((f: any) => f?.mimeType?.includes('image'))?.url}
                          sx={{
                            objectFit: 'cover',
                            height: '100%',
                            width: '100%',
                            display: 'block',
                          }}
                        />
                      )
                      : <NoPhotographyIcon />
                  }
                </Box>
              </Box>
            </Box>
          </Box>
        ))
      }
    </Box>
  );
}

function ButtonNavigation({
  data, pageIndex, setPageIndex, totalPages, hasNextPage, fetchNextPage,
}: any) {
  const { ipadSize } = useDeviceSize();
  return (
    <Box
      width={{ xs: '100%', sm: '80%' }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginTop={2}
    >
      <Button
        type="button"
        disabled={pageIndex === 0}
        variant="contained"
        startIcon={<ArrowBackIosIcon />}
        sx={{
          width: { xs: 100, sm: 150 },
        }}
        onClick={() => {
          setPageIndex(() => pageIndex - 1);
        }}
      >
        {ipadSize ? 'Précédent' : 'Préc.'}
      </Button>
      <Typography>
        {`${pageIndex + 1} / ${totalPages + 1}`}
      </Typography>
      <Button
        variant="contained"
        disabled={data && pageIndex === totalPages}
        endIcon={<ArrowForwardIosIcon />}
        sx={{
          width: { xs: 100, sm: 150 },
        }}
        onClick={() => {
          if (hasNextPage) {
            fetchNextPage();
          }
          setPageIndex(() => pageIndex + 1);
        }}
      >
        {ipadSize ? 'Suivant' : 'Suiv.'}
      </Button>
    </Box>
  );
}

const Text = styled('strong')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export {
  DisplayProperties,
};
