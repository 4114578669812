import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { stateOptions } from '../../data/data';
import { AllowedPropertyState } from '../../api';

function FilterPropertyList({ queryParamsValue, setQueryParamsValue }: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<any>({
    state: searchParams.get('state') ? stateOptions.find((f) => f?.value.toString() === searchParams.get('state')) : { value: AllowedPropertyState.IN_PROGRESS, label: 'En cours' },
  });

  useEffect(() => {
    const searchParamsTmp = new URLSearchParams();
    if (filter.state) {
      searchParamsTmp.set('state', filter.state);
      setQueryParamsValue((old: any) => ({ ...old, state: filter?.state?.value ?? null }));
    }
  }, [filter]);

  useEffect(() => {
    const defaultUrl = new URLSearchParams();
    Object.keys(filter).forEach((key) => {
      if (filter[key] && filter[key]?.length !== 0) {
        defaultUrl.set(key, queryParamsValue[key]);
      }
    });
    setSearchParams(defaultUrl.toString());
  }, [filter, queryParamsValue, setSearchParams]);

  return (
    <Autocomplete
      value={filter?.state}
      onChange={(_, value) => {
        setQueryParamsValue((old: any) => ({ ...old, state: value?.value ?? null }));
        setFilter((oldFilter: any) => ({ ...oldFilter, state: value }));
      }}
      disablePortal
      disableClearable
      options={stateOptions}
      renderInput={
        (params) => (
          <TextField
            {...params}
            label="Activité"
            fullWidth
            size="small"
            sx={{
              width: 150,
              label: {
                fontWeight: '400',
                color: 'text.primary',
              },
            }}
          />
        )
      }
    />
  );
}

export {
  FilterPropertyList,
};
