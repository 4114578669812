import React from 'react';
import flat from '../../assets/property/appartement.png';
import { AllowedPropertyType } from '../../api';
import house from '../../assets/property/maison.png';
import building from '../../assets/property/immeuble.png';
import land from '../../assets/property/terrain.png';
import { TypeOfProperty } from '../components/type-of-property';

function ContainerTypeOfProperty() {
  const typeOfProperty = [{
    text: 'Appartement',
    img: flat,
    url: '/form/form-start',
    param: AllowedPropertyType.FLAT,
    disabled: false,
  }, {
    text: 'Maison',
    img: house,
    url: '/form/form-start',
    param: AllowedPropertyType.HOUSE,
    disabled: true,
  }, {
    text: 'Immeuble',
    img: building,
    url: '/form/form-start',
    param: AllowedPropertyType.BUILDING,
    disabled: true,
  }, {
    text: 'Terrain',
    img: land,
    url: '/form/form-start',
    param: AllowedPropertyType.LAND,
    disabled: true,
  }];

  return (
    <TypeOfProperty typeOfProperty={typeOfProperty} />
  );
}

export {
  ContainerTypeOfProperty,
};
