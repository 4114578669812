import React from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import CloseIcon from '@mui/icons-material/Close';
import { MaxWidthContainer } from '../../../utils/components/max-width-container';
import logoLsi from '../../../assets/common/logo_lsi_blanc.svg';
import { useDeviceSize } from '../../../hooks/utils/device-size.hook';
import { LinkWithQuery } from '../../../utils/components/link-with-query-params';

function MobileHeader({
  menu, queryUser, logout, openMobileMenu, setOpenMobileMenu,
}: any) {
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        width="100%"
        height={100}
        bgcolor="secondary.main"
        zIndex={19000}
        sx={{
          display: { xs: openMobileMenu ? 'none' : 'inherit', md: 'none' },
          boxShadow: !openMobileMenu ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none',
        }}
      >
        <MaxWidthContainer>
          <Box
            width="100%"
            height="100px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Box
              paddingLeft={3}
              width={{ xs: '25%', sm: '15%' }}
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={() => navigate('/')}
            >
              <Box
                component="img"
                sx={{
                  objectFit: 'cover',
                  width: '100%',
                }}
                src={logoLsi}
              />
            </Box>
            <Box paddingRight={3}>
              <IconButton
                sx={{
                  color: 'text.secondary',
                }}
                onClick={() => setOpenMobileMenu(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </MaxWidthContainer>
      </Box>
      <MobileMenu
        openMobileMenu={openMobileMenu}
        setOpenMobileMenu={setOpenMobileMenu}
        menu={menu}
        logout={logout}
        queryUser={queryUser}
      />
    </Box>
  );
}

function MobileMenu({
  openMobileMenu,
  setOpenMobileMenu,
  menu,
  disabled,
  logout,
  queryUser,
}: any) {
  const { desktopSize } = useDeviceSize();
  const navigate = useNavigate();
  return (
    <Box
      bgcolor="secondary.main"
      width="100%"
      display={openMobileMenu && !desktopSize ? 'flex' : 'none'}
      zIndex={100000}
      sx={{
        height: '100dvh !important',
      }}
    >
      <Box width="100%" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" height="100px">
          <Box
            paddingLeft={3}
            width={{ xs: '25%', sm: '15%' }}
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              navigate('/');
              setOpenMobileMenu(false);
            }}
          >
            <Box
              component="img"
              sx={{
                objectFit: 'cover',
                width: '100%',
              }}
              src={logoLsi}
            />
          </Box>
          <Box paddingRight={3}>
            <IconButton
              sx={{
                color: 'text.secondary',
              }}
              onClick={() => setOpenMobileMenu(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box width="80%">
          {menu.map((e: any) => {
            if (disabled) return null;
            return (
              <LinkWithQuery
                to={`/${e.url}`}
                key={e?.value}
                disabled={disabled}
                onClick={() => {
                  setOpenMobileMenu(false);
                }}
              >
                <Box paddingY="5%">
                  <Typography textAlign="right" variant="h5" color="text.secondary">
                    {e.value}
                  </Typography>
                </Box>
              </LinkWithQuery>
            );
          })}
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography
            color="text.secondary"
            variant="caption"
            textAlign="center"
            marginBottom={2}
          >
            {queryUser?.data?.findUserLoggedIn?.email}
          </Typography>
          <Button
            onClick={() => logout()}
            variant="outlined"
            color="error"
            endIcon={<PowerOffIcon />}
            sx={{
              textTransform: 'none',
            }}
          >
            Se déconnecter
          </Button>
        </Box>
        <Box width="80%" display="flex" justifyContent="space-between" alignItems="flex-end" marginBottom={3}>
          <Box
            component="a"
            style={{ textDecoration: 'none' }}
            href="https://lessecretsdelimmo.fr/mentions-legales"
            target="_blank"
            rel="noreferrer"
          >
            <Typography color="primary" variant="caption" gutterBottom>
              Mentions légales
            </Typography>
          </Box>
          <Box
            component="a"
            href="https://lessecretsdelimmo.fr/confidentialite-donnees-personnelles"
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noreferrer"
          >
            <Typography color="primary" variant="caption">
              Données personnelles
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export {
  MobileHeader,
};
