import { useSnackbar } from 'notistack';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { TextFieldCustom } from '../inputs/textfield';
import { PhoneCustom } from '../inputs/phone-custom';
import AddressCustom from '../inputs/autocompleteadress';
import { useCreateRealEstateAgency } from '../../hooks/mutate/create-real-estate-agency-mutation.hook';
import { useUpdateRealEstateAgency } from '../../hooks/mutate/update-real-estate-agency-mutation.hook';

function AddAgencyContainer({ setOpenAddAgency, queryAgency, setValue }: any) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    control, handleSubmit, watch,
  } = useForm<any>({
    mode: 'onChange',
  });

  const mutate = useCreateRealEstateAgency({
    onSuccess: (resp: any) => {
      queryAgency.refetch();
      setValue('realEstateAgency', { value: resp?.createRealEstateAgency?._id, label: `${resp?.createRealEstateAgency?.realEstateAgencyName} - ${resp?.createRealEstateAgency?.realEstateAgencyAddress}` });
      setOpenAddAgency(false);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any) => {
    const formValid: any = {
      createRealEstateAgencyInput: {
        realEstateAgencyAddress: form?.realEstateAgencyAddress?.address_line_1,
        realEstateAgencyName: form?.realEstateAgencyName,
      },
    };
    mutate({ ...formValid });
  };

  return (
    <Box
      marginBottom={6}
    >
      <TextFieldCustom
        name="realEstateAgencyName"
        control={control}
        rules={{ required: 'Veuillez renseigner un nom' }}
        required
        label="Nom de l'agence"
        height={55}
      />
      <AddressCustom
        size="small"
        control={control}
        rules={{ required: 'Veuillez renseigner une adresse' }}
        name="realEstateAgencyAddress"
        label="Adresse de l'agence *"
        height={55}
      />
      <Box display="flex">
        <Button
          color="error"
          type="reset"
          onClick={() => setOpenAddAgency(false)}
          sx={{
            marginRight: 1,
            textTransform: 'none',
          }}
        >
          Annuler
        </Button>
        <Button
          color="primary"
          fullWidth
          type="submit"
          variant="contained"
          endIcon={<AddIcon />}
          onClick={handleSubmit(onSubmit)}
          disabled={!watch('realEstateAgencyName') || !watch('realEstateAgencyAddress')}
          sx={{
            marginLeft: 1,
            textTransform: 'none',
            color: (theme) => theme.palette.background.paper,
          }}
        >
          Ajouter agence immobilière
        </Button>
      </Box>
    </Box>
  );
}

function AddAgentContainer({
  setOpen, agencyId, queryAgency, setValue,
}: any) {
  const { enqueueSnackbar } = useSnackbar();
  const countryCode = parsePhoneNumber('+33000000000', 'FR');
  const [country, setCountry] = useState<any>({
    countryCode: countryCode?.country ? countryCode?.country : 'FR',
    dialCode: countryCode?.countryCallingCode ? countryCode?.countryCallingCode : '33',
  });

  const {
    control, handleSubmit, watch,
  } = useForm<any>({
    mode: 'onChange',
  });

  const mutate = useUpdateRealEstateAgency({
    onSuccess: (resp: any) => {
      const agentArray = resp?.updateRealEstateAgency?.realEstateAgents[resp?.updateRealEstateAgency?.realEstateAgents.length - 1];
      queryAgency.refetch();
      setValue('realEstateAgent', { value: agentArray?._id, label: agentArray?.name });
      setOpen(false);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any) => {
    const formValid: any = {
      updateRealEstateAgencyInput: {
        _id: agencyId,
        realEstateAgents: {
          email: form?.email,
          name: form?.name,
          phone: form?.phone?.replaceAll(' ', ''),
        },
      },
    };
    mutate({ ...formValid });
  };

  return (
    <Box marginBottom={3}>
      <TextFieldCustom
        name="name"
        required
        control={control}
        rules={{
          required: 'Veuillez renseigner un nom',
        }}
        label="Nom de l'agent immobilier"
        height={55}
      />
      <TextFieldCustom
        type="email"
        name="email"
        control={control}
        label="Email de l'agent immobilier"
        height={55}
      />
      <PhoneCustom
        control={control}
        name="phone"
        label="+33 X XX XX XX XX"
        paddingBottom="10px"
        setCountry={setCountry}
        rules={{
          validate: (value: string) => {
            if (value) {
              return isValidPhoneNumber(value, country?.countryCode?.toUpperCase()) || `Merci de renseigner un format valide (${country?.countryCallingCode} XX XX XX XX).`;
            }
            return null;
          },
        }}
      />
      <Box display="flex">
        <Button
          color="error"
          type="reset"
          onClick={() => setOpen(false)}
          sx={{
            marginRight: 1,
            textTransform: 'none',
          }}
        >
          Annuler
        </Button>
        <Button
          color="primary"
          fullWidth
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          endIcon={<AddIcon />}
          disabled={!watch('name')}
          sx={{
            marginLeft: 1,
            textTransform: 'none',
            color: (theme) => theme.palette.background.paper,
          }}
        >
          Ajouter agent immobilier
        </Button>
      </Box>
    </Box>
  );
}

export {
  AddAgencyContainer,
  AddAgentContainer,
};
