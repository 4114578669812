import React from 'react';
import {
  Autocomplete, Box, Grid, styled, TextField, TextFieldProps, Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';

function AutocompleteCustom({
  control, name, label, tab, multiple = false, rules = null, id,
}: any) {
  return (
    <Grid item minHeight={80}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <Box>
            <Autocomplete
              {...field}
              id={id}
              options={tab}
              value={field.value ? field?.value : multiple ? [] : ''}
              isOptionEqualToValue={(option, value) => option.label === value}
              onBlur={field.onBlur}
              multiple={multiple}
              onChange={(e: any, value: any) => {
                field.onChange(value);
              }}
              renderInput={
              (params: JSX.IntrinsicAttributes & TextFieldProps) => (
                <CssAutocomplete
                  {...params}
                  fullWidth
                  label={label}
                  size="small"
                  variant="outlined"
                />
              )
            }
            />
            {fieldState?.error?.message && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
          </Box>
        )}
      />
    </Grid>
  );
}

const CssAutocomplete = styled(TextField)(({ theme }) => ({
  label: {
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
  input: {
    color: theme.palette.text.primary,
    padding: '4px 0px 4px !important',
  },
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '.css-ke91x2-MuiInputBase-root-MuiInput-root:before': {
    borderBottomColor: theme.palette.primary.main,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.primary.main,
  },
  '.css-i4bv87-MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
  '.css-ptiqhd-MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
}));

export {
  AutocompleteCustom,
};
