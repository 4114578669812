import React, { useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import {
  AccordionContainer, GridContainerFormInput, SectionTitle, SubmitButtonForm,
} from '../../components/containerform';
import { AutocompleteCustom } from '../../../common/inputs/autocompletecustom';
import { collectiveOwnershipWorksOptions, landlordFeesOptions } from '../../../data/data';
import { CurrencyTextFieldCustom } from '../../../common/inputs/currencytextfield';
import { SwitchCustom } from '../../../common/inputs/switchcustom';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import { PercentageTextField } from '../../../common/inputs/percentagetextfield';
import { useUpdateProperty } from '../../../hooks/mutate/update-property-mutation.hook';

interface IFormInput {
  type_landlord_fees: []
  landlord_fees: string
  landlord_surface_rate: string
  building_stories_number: string
  building_year: string
  shared_flat_available: string
  planned_renovation_work: string
  collective_ownership_works: string
}

function GeneralInformation({
  indexAccordion, setIndexAccordion, index, indexMax, title, data, queryInfo,
}: any) {
  const [searchParams] = useSearchParams();

  const formatted = {
    ...data,
    type_landlord_fees: data?.type_landlord_fees
      ?.map((e: string) => landlordFeesOptions?.find((elem) => elem.value === e))
      || [],
    collective_ownership_works: collectiveOwnershipWorksOptions.find((f) => f.value === data?.collective_ownership_works),
  };

  const {
    control, handleSubmit, reset, watch,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return { ...formatted };
    }, [data]),
  });

  useEffect(() => {
    reset({ ...formatted });
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
      // eslint-disable-next-line no-param-reassign
      setIndexAccordion(indexAccordion += 1);
      // eslint-disable-next-line no-param-reassign
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        general_information: {
          type_landlord_fees: form?.type_landlord_fees?.map((e: any) => e?.value),
          landlord_fees: Number(form?.landlord_fees),
          landlord_surface_rate: Number(form?.landlord_surface_rate),
          building_stories_number: Number(form?.building_stories_number),
          building_year: Number(form?.building_year),
          shared_flat_available: form?.shared_flat_available,
          planned_renovation_work: form?.planned_renovation_work,
          planned_renovation_price: form?.planned_renovation_price,
          collective_ownership_works: form?.collective_ownership_works?.value,
        },
      },
    };

    Object.keys(formValid.data.general_information).forEach((key) => {
      if (formValid.data.general_information[key] === undefined || formValid.data.general_information[key] === null || Number.isNaN(formValid.data.general_information[key])) {
        delete formValid.data.general_information[key];
      }
    });
    mutate({ ...formValid });
  };

  return (
    <AccordionContainer
      title={title}
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
      index={index}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box paddingX={{ xs: 0, md: 7 }}>
          <Box>
            <SectionTitle text="Informations concernant le bien" />
            <GridContainerFormInput
              xsCol={1}
              smCol={1}
            >
              <Grid item xs={2}>
                <AutocompleteCustom
                  control={control}
                  tab={landlordFeesOptions}
                  multiple
                  name="type_landlord_fees"
                  label="Contenu des charges de copropriété"
                />
                <CurrencyTextFieldCustom
                  control={control}
                  name="landlord_fees"
                  label="Charges annuelles de copropriété"
                />
                <PercentageTextField
                  control={control}
                  name="landlord_surface_rate"
                  label="Quote part du lot"
                />
                <TextFieldCustom
                  control={control}
                  name="building_stories_number"
                  label="Nombre d'étages de l'immeuble"
                  type="number"
                  numberMax={100}
                />
                <TextFieldCustom
                  control={control}
                  name="building_year"
                  label="Année de construction"
                  type="number"
                  numberMin={1700}
                  numberMax={Number(format(new Date(), 'yyyy'))}
                />
                <SwitchCustom
                  control={control}
                  name="shared_flat_available"
                  label="Colocation possible dans le règlement de copropriété"
                  center
                />
                <SwitchCustom
                  control={control}
                  name="planned_renovation_work"
                  label="Travaux prévus dans la copropriété"
                  center
                />
                {
                    // @ts-ignore
                    watch('planned_renovation_work') === true
                    && (
                      <>
                        <AutocompleteCustom
                          control={control}
                          tab={collectiveOwnershipWorksOptions}
                          name="collective_ownership_works"
                          label="Travaux de copropriété"
                        />
                        <CurrencyTextFieldCustom
                          control={control}
                          name="planned_renovation_price"
                          label="Estimation prix des travaux copro"
                        />
                      </>
                    )
                }
              </Grid>
            </GridContainerFormInput>
            <SubmitButtonForm index={index} indexMax={indexMax} />
          </Box>
        </Box>
      </Box>
    </AccordionContainer>
  );
}

export {
  GeneralInformation,
};
