import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box, FormControlLabel, Grid, Switch,
} from '@mui/material';
import { useDeviceSize } from '../../hooks/utils/device-size.hook';

function SwitchCustom({
  control, name, label, center = false,
}: any) {
  const { ipadSize } = useDeviceSize();
  return (
    <Grid item minHeight={80} display="flex" justifyContent={{ xs: center ? 'center' : 'flex-start', sm: 'flex-start' }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Box>
              <FormControlLabel
                labelPlacement={ipadSize ? 'start' : center ? 'top' : 'start'}
                sx={{
                  textAlign: { xs: center ? 'center' : 'left ', sm: 'left' },
                }}
                control={(
                  <Switch
                    onChange={(e) => field.onChange(e)}
                    checked={field?.value}
                  />
                    )}
                label={label}
              />
            </Box>
          );
        }}
      />
    </Grid>
  );
}

export {
  SwitchCustom,
};
