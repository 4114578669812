import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { SimulationContainer } from '../components/simulationcontainer';
import { SummarySimulation } from '../components/summarysimulation';
import { GlobalBudget } from '../components/globalbudget';
import { CashFLow } from '../components/cashflow';
import { PricePerSurface } from '../components/pricepersurface';
import { euro } from '../../../common/inputs/format/euro-format';
import { useGlobalConfig } from '../../../hooks/query/global-config.hook';

function Simulation({ queryInfo }: any) {
  const { getGlobalConfig } = useGlobalConfig();
  const simulationColor = {
    red: '#940505',
    green: '#12783b',
    greenText: '#17551D',
    blueDarkBar: '#91A7BA',
    blueLightBar: '#D8E3EB',
    pie1: '#91A7BA',
    pie2: '#DBEBD8',
    pie3: '#D8E3EB',
    pie4: '#F7F1E3',
    pie5: '#EBD8E5',
  };

  return (
    <Box>
      {queryInfo?.data?.getPropertiesById
        && (
          <>
            <SimulationContainer queryInfo={queryInfo} simulationColor={simulationColor} />
            <ContainerSynthese
              title="Synthèse"
            >
              <SummarySimulation queryInfo={queryInfo} simulationColor={simulationColor} />
            </ContainerSynthese>
            {queryInfo?.data?.getPropertiesById?.finance?.district_price_per_m2 && queryInfo?.data?.getPropertiesById?.finance?.district_price_per_m2 !== 0
              ? (
                <ContainerSynthese
                  value={euro.format(queryInfo?.data?.getPropertiesById?.finance?.expected_price_per_m2)}
                  title="Prix au m2"
                >
                  <PricePerSurface queryInfo={queryInfo} simulationColor={simulationColor} />
                </ContainerSynthese>
              ) : null}
            <ContainerSynthese
              title="Budget global"
              value={euro.format(queryInfo?.data?.getPropertiesById?.finance?.total_budget)}
            >
              <GlobalBudget queryInfo={queryInfo} simulationColor={simulationColor} />
            </ContainerSynthese>
            <ContainerSynthese
              title="Cash Flow"
              value={null}
            >
              {getGlobalConfig.isSuccess
                && <CashFLow queryInfo={queryInfo} simulationColor={simulationColor} getGlobalConfig={getGlobalConfig} />}
            </ContainerSynthese>
          </>
        )}
    </Box>
  );
}

function ContainerSynthese({ children, title, value }: any) {
  return (
    <Paper sx={{
      boxShadow: 'none',
      border: 'solid 0.1px black',
      display: 'flex',
      padding: 2,
      bgcolor: 'background.paper',
      flexDirection: 'column',
      borderRadius: '5px !important',
      marginBottom: 3,
      ':before': {
        content: 'none',
      },
    }}
    >
      <Box display="flex" justifyContent="space-between" marginBottom={1}>
        <Typography
          variant="h5"
          color="text.primary"
          fontWeight="bold"
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          color="text.primary"
          fontWeight="bold"
        >
          {value}
        </Typography>
      </Box>
      {children}
    </Paper>
  );
}

export {
  Simulation,
};
