import React, { useState } from 'react';
import { Box } from '@mui/material';
import { RenovationItem } from '../components/renovationitem';
import { BuildingTrade } from '../components/building-trade';

function Renovation({ queryInfo }: any) {
  const [indexAccordion, setIndexAccordion] = useState<any>(0);
  const indexMax = 2;

  const data = queryInfo?.data?.getPropertiesById;
  return (
    <Box>
      <RenovationItem
        title="Travaux"
        indexAccordion={indexAccordion}
        setIndexAccordion={setIndexAccordion}
        index={0}
        indexMax={indexMax}
        queryInfo={queryInfo}
        data={data?.renovation}
      />
      <BuildingTrade
        title="Corps de métier à solliciter"
        indexAccordion={indexAccordion}
        setIndexAccordion={setIndexAccordion}
        index={1}
        indexMax={indexMax}
        queryInfo={queryInfo}
        data={data?.renovation?.professionNeeded}
      />
    </Box>
  );
}

export {
  Renovation,
};
