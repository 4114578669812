import { useGetAllRealEstateAgenciesQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetAllRealEstateAgencies = () => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo = useGetAllRealEstateAgenciesQuery(headerWithAuth, {}, { enabled: !!accessToken });

  return queryInfo;
};

export { useGetAllRealEstateAgencies };
