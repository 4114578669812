import React, { useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { getTime } from 'date-fns';
import { useSnackbar } from 'notistack';
import {
  AccordionContainer, GridContainerFormInput, SectionTitle, SubmitButtonForm,
} from '../../components/containerform';
import { AutocompleteCustom } from '../../../common/inputs/autocompletecustom';
import {
  dpeGesOptions, exploitationOptions, flatTypeOptions, orientationOptions,
} from '../../../data/data';
import { CurrencyTextFieldCustom } from '../../../common/inputs/currencytextfield';
import { SwitchCustom } from '../../../common/inputs/switchcustom';
import { SurfaceTextField } from '../../../common/inputs/surfacetextfield';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import { CalendarCustom } from '../../../common/inputs/calendarcustom';
import { useUpdateProperty } from '../../../hooks/mutate/update-property-mutation.hook';

interface IFormInput {
  flat_type: string
  upfront_price: string
  already_visited: string
  surface: string
  orientation: string
  property_story: string
  already_rent: string
  rentingEndDate: string
  current_rent: string
  expected_rent: string
  dpe_audit: string
  ges_audit: string
  tax: string
  flat_number: string
  visited_date: string
  exploitation: string
}

function Description({
  indexAccordion, setIndexAccordion, index, indexMax, data, queryInfo, title,
}: any) {
  const [searchParams] = useSearchParams();

  const format = {
    ...data,
    visited_date: data?.visited_date ? new Date(data?.visited_date) : null,
    rentingEndDate: data?.rentingEndDate ? new Date(data?.rentingEndDate) : null,
    dpe_audit: dpeGesOptions.find((f) => f.value === data?.dpe_audit),
    ges_audit: dpeGesOptions.find((f) => f.value === data?.ges_audit),
    flat_type: flatTypeOptions.find((f) => f.value === data?.flat_type),
    orientation: orientationOptions.find((f) => f.value === data?.orientation),
    exploitation: exploitationOptions.find((f) => f.value === data?.exploitation),
  };

  const {
    control, handleSubmit, reset, watch,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return { ...format };
    }, [data]),
  });

  useEffect(() => {
    reset({ ...format });
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: () => {
      queryInfo.refetch();
      // eslint-disable-next-line no-param-reassign
      setIndexAccordion(indexAccordion += 1);
      // eslint-disable-next-line no-param-reassign
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        description: {
          flat_type: form?.flat_type?.value,
          upfront_price: Number(form?.upfront_price),
          already_visited: form?.already_visited,
          surface: Number(form?.surface),
          orientation: form?.orientation?.value,
          visited_date: form?.visited_date ? getTime(form?.visited_date) : null,
          already_rent: form?.already_rent,
          property_story: Number(form?.property_story),
          dpe_audit: form?.dpe_audit?.value,
          flat_number: form?.flat_number,
          ges_audit: form?.ges_audit?.value,
          current_rent: Number(form?.current_rent),
          rentingEndDate: form?.rentingEndDate ? getTime(form?.rentingEndDate) : null,
          expected_rent: Number(form?.expected_rent),
          tax: Number(form?.tax),
          exploitation: form?.exploitation?.value,
        },
      },
    };
    Object.keys(formValid.data.description).forEach((key) => {
      if (formValid.data.description[key] === undefined || formValid.data.description[key] === null || Number.isNaN(formValid.data.description[key])) {
        delete formValid.data.description[key];
      }
    });
    mutate({ ...formValid });
  };

  return (
    <AccordionContainer
      title={title}
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
      index={index}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box paddingX={{ xs: 0, md: 7 }}>
          <Box>
            <SectionTitle text="Informations concernant le bien" />
            <GridContainerFormInput
              xsCol={1}
              smCol={1}
            >
              <Grid item xs={2}>
                <AutocompleteCustom
                  control={control}
                  tab={flatTypeOptions}
                  name="flat_type"
                  label="Type de bien"
                />
                <CurrencyTextFieldCustom
                  control={control}
                  name="upfront_price"
                  label="Prix affiché"
                />
                <SwitchCustom
                  control={control}
                  name="already_visited"
                  label="Déjà visité"
                />
                {
                  // @ts-ignore
                  watch('already_visited')
                  && (
                    <CalendarCustom
                      name="visited_date"
                      control={control}
                      minDate={new Date('01/01/1900')}
                      label="Date de visite"
                    />
                  )
                }
                <SurfaceTextField
                  control={control}
                  name="surface"
                  label="Surface habitable"
                  rules={{
                    validate: (value: string) => {
                      return (Number(value) >= 5 && Number(value) <= 1500) || 'Merci de renseigner une surface entre 5 et 1500 m2';
                    },
                  }}
                />
                <AutocompleteCustom
                  control={control}
                  tab={orientationOptions}
                  name="orientation"
                  label="Orientation du bien"
                />
                <SwitchCustom
                  control={control}
                  name="already_rent"
                  label="Déjà loué"
                />
                {
                  // @ts-ignore
                  watch('already_rent') === true
                  && (
                    <>
                      <CurrencyTextFieldCustom
                        control={control}
                        name="current_rent"
                        label="Loyer actuel charges comprises"
                      />
                      <CalendarCustom
                        name="rentingEndDate"
                        control={control}
                        minDate={new Date()}
                        label="Date de fin de bail"
                      />
                    </>
                  )
                }
                <CurrencyTextFieldCustom
                  control={control}
                  name="expected_rent"
                  label="Loyer prévu charges comprises"
                />
                <CurrencyTextFieldCustom
                  control={control}
                  name="tax"
                  label="Taxes foncières"
                />
                <TextFieldCustom
                  control={control}
                  name="property_story"
                  label="Étage du bien"
                  type="number"
                  numberMax={100}
                />
                <TextFieldCustom
                  control={control}
                  name="flat_number"
                  label="Numéro d'appartement"
                />
                <AutocompleteCustom
                  control={control}
                  tab={dpeGesOptions}
                  name="dpe_audit"
                  label="DPE"
                />
                <AutocompleteCustom
                  control={control}
                  tab={dpeGesOptions}
                  name="ges_audit"
                  label="GES"
                />
                <AutocompleteCustom
                  control={control}
                  tab={exploitationOptions}
                  name="exploitation"
                  label="Exploitation"
                />
              </Grid>
            </GridContainerFormInput>
            <SubmitButtonForm index={index} indexMax={indexMax} />
          </Box>
        </Box>
      </Box>
    </AccordionContainer>
  );
}

export {
  Description,
};
