import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box, TextField, Autocomplete, styled, Grid, Typography,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// @ts-ignore
import parse from 'autosuggest-highlight/parse';
// @ts-ignore
import throttle from 'lodash/throttle';
import { Controller } from 'react-hook-form';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = 'AIzaSyD7mZCiA6--ihPLjIgegOsXVRSaiNC9eYw';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null, details: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  // eslint-disable-next-line camelcase
  main_text: string;
  // eslint-disable-next-line camelcase
  secondary_text: string;
  // eslint-disable-next-line camelcase
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  // eslint-disable-next-line camelcase
  structured_formatting: StructuredFormatting;
}

export default function AddressCustom({
  control, label, name, height = 80,
}: any) {
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }
    loaded.current = true;
  }

  const fetch = useMemo(
    () => throttle(
      (
        request: { input: string },
        callback: (results?: readonly PlaceType[]) => void,
      ) => {
        (autocompleteService.current as any).getPlacePredictions(
          request,
          callback,
        );
      },
      200,
    ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.details && (window as any).google) {
      autocompleteService.details = new (
        window as any
      ).google.maps.places.PlacesService(document.createElement('div'));
    }

    if (!autocompleteService.current || !autocompleteService.details) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      // @ts-ignore
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Grid item height={height}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Autocomplete
              {...field}
              id="google-map-demo"
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
              filterOptions={(x) => x}
              autoComplete
              options={options}
              includeInputInList
              filterSelectedOptions
              value={field.value ? field.value.address_line_1 : ''}
              onChange={(event: any, newValue: PlaceType | null) => {
                // @ts-ignore
                autocompleteService.details.getDetails({ placeId: newValue.place_id }, (result, status) => {
                  field.onChange({
                    address_line_1: result.formatted_address || '',
                    country: result?.address_components?.find((r: any) => r.types.includes('country'))?.long_name || '',
                    locality: result?.address_components?.find((r: any) => r.types.includes('locality'))?.long_name || '',
                    zipcode: result?.address_components?.find((r: any) => r.types.includes('postal_code'))?.long_name || '',
                  });
                });
                setValue(newValue);
                setOptions(newValue ? [newValue, ...options] : options);
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <CssAutocomplete
                  {...params}
                  fullWidth
                  label={label}
                  variant="outlined"
                  size="small"
                />
              )}
              renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match: any) => [match.offset, match.offset + match.length]),
                );
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Box
                          component={LocationOnIcon}
                          sx={{ color: 'primary.main', mr: 2 }}
                        />
                      </Grid>
                      <Grid item xs>
                        {parts.map((part: { highlight: any; text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                        <Typography variant="body2" color="primary.main">
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          );
        }}
      />
    </Grid>
  );
}

const CssAutocomplete = styled(TextField)(({ theme }) => ({
  label: {
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
  input: {
    color: theme.palette.text.primary,
    padding: '4px 0px 4px !important',
  },
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '.css-ke91x2-MuiInputBase-root-MuiInput-root:before': {
    borderBottomColor: theme.palette.primary.main,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.primary.main,
  },
  '.css-i4bv87-MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
  '.css-ptiqhd-MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
}));
