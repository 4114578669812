import React from 'react';
import { Button } from '@mui/material';

function ButtonCustom({
  onClick, disabled, color, startIcon, label, endIcon, type, margin = true,
}: any) {
  return (
    <Button
      type={type}
      disabled={disabled}
      onClick={onClick}
      color={color}
      variant="contained"
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        width: { xs: 'auto', sm: '200px' },
        paddingX: { xs: 3, sm: 0 },
        height: '50px',
        marginBottom: margin ? 3 : 0,
        color: (theme) => (color === 'primary' ? theme.palette.background.paper : null),
        borderRadius: '5px',
        fontWeight: 'bold',
        fontSize: '18px',
        textTransform: 'none',
      }}
    >
      {label}
    </Button>
  );
}

export {
  ButtonCustom,
};
