import React, { useEffect, useState } from 'react';
import {
  Box, styled, Typography,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useForm, FormProvider } from 'react-hook-form';
import { ButtonCustom } from '../../../common/inputs/buttoncustom';
import {
  AllowedPropertyType,
} from '../../../api';
import { FormLeft } from '../components/form-left';
import { FormRight } from '../components/form-right';
import { useCreateProperty } from '../../../hooks/mutate/create-property-mutation.hook';

interface IFormInput {
  street_address: string
  address_details: string,
  realEstateAgency: string,
  realEstateAgent: string,
}

function FormStart() {
  const [successId, setSuccessId] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const methods = useForm<IFormInput>();

  const {
    control, handleSubmit, watch, setValue,
  } = methods;

  const property_type = searchParams.get('type');

  const chooseVariables = () => {
    switch (property_type) {
      case (AllowedPropertyType.FLAT): {
        return {
          label: 'un appartement',
          redirect: `/form/info?propertyId=${successId}`,
        };
      }
      case (AllowedPropertyType.HOUSE): {
        return {
          label: 'une maison',
          redirect: `/form/info?propertyId=${successId}`,
        };
      }
      case (AllowedPropertyType.BUILDING): {
        return {
          label: 'un immeuble',
          redirect: `/form/info?propertyId=${successId}`,
        };
      }
      case (AllowedPropertyType.LAND): {
        return {
          label: 'un terrain',
          redirect: `/form/info?propertyId=${successId}`,
        };
      }
      default:
        return {
          label: 'Type incorrect',
          redirect: '/add-real-estate',
        };
    }
  };

  useEffect(() => {
    successId && navigate(chooseVariables().redirect);
  }, [successId]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useCreateProperty({
    onSuccess: (resp: any) => {
      setSuccessId(resp?.createProperty?._id);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any) => {
    const formValid: any = {
      data: {
        ad_link: form?.ad_link?.map((e: any) => e?.link),
        type: property_type,
        localisation: {
          street_address: {
            address_line_1: form?.street_address?.address_line_1,
            country: form?.street_address?.country,
            locality: form?.street_address?.locality,
            zipcode: form?.street_address?.zipcode,
            region: form?.street_address?.region || '',
          },
          address_details: form?.address_details,
        },
        realEstateAgency: form.realEstateAgency?.value,
        realEstateAgent: form?.realEstateAgent?.value,
      },
    };
    mutate({ ...formValid });
  };

  return (
    <Box
      width="90%"
      paddingY={{ xs: 5, md: 0 }}
      marginTop={{ xs: 0, md: '35px' }}
      flexDirection="column"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Typography
        variant="h4"
        fontWeight={600}
        width="100%"
        textAlign={{ xs: 'center', md: 'left' }}
      >
        Vous souhaitez ajouter
        {' '}
        <Text>
          {chooseVariables()?.label}
        </Text>
      </Typography>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
        marginTop={{ xs: 5, md: 6 }}
      >
        <FormProvider {...methods}>
          <Box
            onSubmit={handleSubmit(onSubmit)}
            width="100%"
            component="form"
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
              <FormLeft control={control} />
              <FormRight control={control} watch={watch} setValue={setValue} />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              marginTop={{ xs: 2, md: 10 }}
            >
              <ButtonCustom
                color="primary"
                onClick={() => navigate('/')}
                startIcon={<ArrowBackIosNewIcon />}
                label="Retour"
              />
              <ButtonCustom
                type="submit"
                color="primary"
                disabled={!watch('street_address')}
                endIcon={<ArrowForwardIosIcon />}
                label="Enregistrer"
              />
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </Box>
  );
}

const Text = styled('strong')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export {
  FormStart,
  Text,
};
