import React from 'react';
import { DesktopHeader } from './desktopheader';
import { MobileHeader } from './mobileheader';

function Header({
  openMobileMenu, setOpenMobileMenu, queryUser, menu, logout,
}: any) {
  return (
    <>
      <DesktopHeader
        menu={menu}
        queryUser={queryUser}
        logout={logout}
      />
      <MobileHeader
        menu={menu}
        queryUser={queryUser}
        logout={logout}
        openMobileMenu={openMobileMenu}
        setOpenMobileMenu={setOpenMobileMenu}
      />
    </>
  );
}

export {
  Header,
};
