import React from 'react';
import {
  Box, Switch, Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';

function BooleanWithIconRow({
  control, name, label, watch, icon, height = 'auto',
}: any) {
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({
          field,
        }) => {
          return (
            <Box height={height} display="flex" alignItems="center" justifyContent="space-between" flexDirection="column">
              <Box
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                onClick={() => field.onChange(!field.value)}
                sx={{
                  opacity: watch(name) === false ? 0.5 : 1,
                  transition: 'all 0.3s',
                  cursor: 'pointer',
                }}
              >
                <Typography>{label}</Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Box
                    component="img"
                    src={icon}
                    height={60}
                    sx={{ objectFit: 'cover' }}
                  />
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    sx={{
                      opacity: watch(name) === false ? 0.5 : 1,
                    }}
                  >
                    <Switch
                      onChange={(e) => field.onChange(e)}
                      checked={field?.value}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        }}
      />
    </Box>
  );
}

export {
  BooleanWithIconRow,
};
