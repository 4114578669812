import React, { useState } from 'react';
import {
  Box, Button, Menu, MenuItem, styled, Typography, IconButton, Snackbar, Alert, Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { useGetAccessToken } from '../../../hooks/auth/get-access-token.hook';
import { fileLabelOptionsInput } from '../../../data/data';
import { AllowedPropertyDocumentsLabels } from '../../../api';
import contract_icon from '../../../assets/documents/document_icon_contract.png';
import others_icon from '../../../assets/documents/document_icon_other.png';
import { useDeleteDocumentsFromProperty } from '../../../hooks/mutate/delete-documents-from-property-mutation.hook';

interface IFormInput {
  file: string
}

function Documents({ queryInfo }: any) {
  const [searchParams] = useSearchParams();
  const [openSnack, setOpenSnack] = useState(false);
  const [uploadMessage, setUploadMessage] = useState({ text: '', type: '' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [documentType, setDocumentType] = useState('');
  const propertyId = searchParams.get('propertyId');
  const { accessToken } = useGetAccessToken();
  const [loading, setLoading] = useState(false);

  const open = Boolean(anchorEl);
  const {
    control, handleSubmit, watch, setValue,
  } = useForm<IFormInput>({});

  const onSubmit = (form: any): any => {
    setLoading(true);
    if (form?.file?.type === 'image/heif') {
      setOpenSnack(true);
      setUploadMessage({ text: "le format de l'un de vos fichiers n'est pas valide !", type: 'error' });
    } else {
      const formData = new FormData();
      for (const file of form.file) {
        formData.append('files', file);
      }
      formData.append('propertyId', propertyId ?? '');
      formData.append('label', documentType);

      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${accessToken}`);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
      };

      fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/property/upload`, requestOptions).then((response) => response.json())
        .then((result) => {
          if (result.success) {
            queryInfo.refetch();
            setUploadMessage({ text: 'Votre ou vos document(s) ont été importé avec succès !', type: 'success' });
            setOpenSnack(true);
            setValue('file', '');
            setLoading(false);
          }
        }).catch((err) => {
          setOpenSnack(true);
          setUploadMessage({ text: `Une erreur est survenue : ${err.message}`, type: 'error' });
          setLoading(false);
        });
    }
  };

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
    setUploadMessage({ text: '', type: '' });
  };

  const mutate = useDeleteDocumentsFromProperty({
    onSuccess: () => {
      queryInfo.refetch();
      setOpenSnack(true);
      setValue('file', '');
    },
    onError: (err: any) => {
      setOpenSnack(true);
      setUploadMessage({ text: `Une erreur est survenue : ${err.message}`, type: 'error' });
    },
  });

  const handleClick = (event: { currentTarget: React.SetStateAction<null> }) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const chooseIcon = (type: any) => {
    switch (type) {
      case AllowedPropertyDocumentsLabels?.CO_OWNERSHIP_REGULATIONS:
        return contract_icon;
      case AllowedPropertyDocumentsLabels?.OTHERS:
        return others_icon;
      default:
        return others_icon;
    }
  };

  const checkFileName = (files: any) => {
    if (files) {
      let filesName = '';
      for (const file of files) {
        // eslint-disable-next-line no-multi-assign
        filesName = filesName += `${file?.name}\n`;
      }
      return filesName;
    }
    return 'Le fichier';
  };

  return (
    <Box display="flex" justifyContent="center">
      <Snackbar open={openSnack} onClose={handleCloseSnack} autoHideDuration={5000}>
        <Alert
          severity={uploadMessage?.type === 'success' ? 'success' : 'error'}
          onClose={handleCloseSnack}
          sx={{ width: '100%' }}
        >
          {uploadMessage?.text}
        </Alert>
      </Snackbar>
      <Box display="flex" flexDirection="column" justifyContent="center" width={{ xs: '90%', sm: '100%' }}>
        <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', sm: 'row' }}>
          <Box display="flex" flexDirection="column" paddingLeft={{ xs: 0, sm: 1 }}>
            <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }} marginBottom={{ xs: 2, sm: 0 }}>
              <Typography variant="h4" fontWeight="bold" marginRight={1}>
                Mon espace
              </Typography>
              <Typography variant="h4" fontWeight="bold" color="primary.main">
                documents
              </Typography>
            </Box>
            <Typography
              variant="h6"
              textAlign={{ xs: 'center', sm: 'left' }}
              color="text.primary"
              fontWeight="bold"
            >
              Consultez ou importez vos documents
            </Typography>
          </Box>
          <Box marginTop={{ xs: 2, sm: 0 }} paddingRight={{ xs: 0, sm: 1 }} width={{ xs: '100%', sm: 300 }}>
            <Button
              sx={{
                marginRight: 3, textTransform: 'none', fontWeight: 'bold', color: 'text.secondary',
              }}
              fullWidth
              color="primary"
              variant="contained"
              component="span"
              onClick={(e: any) => handleClick(e)}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Importer vos documents
            </Button>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem disabled>Sélectionner le type de document à importer</MenuItem>
                {fileLabelOptionsInput.map((e, i) => (
                  <label key={e?.label + i.toString()}>
                    <MenuItem>
                      {e.label}
                    </MenuItem>
                    <Controller
                      name="file"
                      control={control}
                      render={({ field }) => (
                        <Input
                          multiple
                          id="contained-button-file"
                          // eslint-disable-next-line no-shadow
                          onChange={(elem) => {
                            // @ts-ignore
                            const file = elem.target.files ?? null;
                            if (!file) return;
                            field.onChange(file);
                            setDocumentType(e.value);
                            setAnchorEl(null);
                          }}
                          type="file"
                        />
                      )}
                    />
                  </label>
                ))}
              </Menu>
              {watch('file')
                && (
                  <Box display="flex" flexDirection="column">
                    <Box
                      bgcolor="text.secondary"
                      marginTop={1}
                      padding={1}
                      display="flex"
                      borderRadius="4px"
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          width: '95%',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {
                          checkFileName(watch('file'))
                        }
                      </Typography>
                      <Box
                        width="5%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setValue('file', '')}
                      >
                        <ClearIcon fontSize="small" color="error" />
                      </Box>
                    </Box>
                    <LoadingButton
                      fullWidth
                      sx={{
                        marginRight: 3, marginTop: 1, textTransform: 'none', fontWeight: 'bold',
                      }}
                      color="primary"
                      variant="outlined"
                      type="submit"
                      loading={loading}
                    >
                      Télécharger
                    </LoadingButton>
                  </Box>
                )}
            </Box>
          </Box>
        </Box>
        <Box width="100%" display="flex" justifyContent="center">
          <Box
            width="90%"
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            padding={{ xs: 0, md: 5 }}
            marginTop={{ xs: 3, md: 10 }}
            borderRadius="5px"
          >
            {queryInfo?.data?.getPropertiesById?.propertyDocuments?.map((row: any, i: any) => (
              <Box
                key={row?._id}
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginBottom={1}
                width={{ xs: '50%', sm: '18%' }}
              >
                <Box
                  width={50}
                  margin={2}
                  height={50}
                  marginBottom={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    component="img"
                    src={chooseIcon(row?.type)}
                    width="70%"
                    sx={{ objectFit: 'contain', display: 'block' }}
                  />
                </Box>
                <Box display="flex">
                  <Typography
                    marginY={1}
                    variant="caption"
                    fontWeight="bold"
                  >
                    {row?.type === AllowedPropertyDocumentsLabels?.CO_OWNERSHIP_REGULATIONS ? 'Copro' : fileLabelOptionsInput?.find((f) => f?.value === row?.type)?.label}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setUploadMessage({
                        text: `Le document ${row?.path.split('-').map((e: any) => e).slice(1).join('-')} a bien été supprimé !`,
                        type: 'success',
                      });
                      mutate({ documentId: row?._id });
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon
                      fontSize="small"
                    />
                  </IconButton>
                </Box>
                <Tooltip title={row?.path.split('-').map((e: any) => e).slice(1).join('-')}>
                  <Box
                    component="a"
                    href={row?.url}
                    target="_blank"
                    rel="noreferrer"
                    sx={{ textDecoration: 'none' }}
                  >
                    <Typography
                      width="130px"
                      variant="body2"
                      textAlign="center"
                      color="primary"
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        textDecoration: 'underline',
                        overflow: 'hidden',
                      }}
                    >
                      {row?.path.split('-').map((e: any) => e).slice(1).join('-')}
                    </Typography>
                  </Box>
                </Tooltip>
                <Typography variant="body2">{format(new Date(row?.createdAt), 'dd/MM/yyyy')}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const Input = styled('input')({
  display: 'none',
});

export {
  Documents,
};
