import React from 'react';
import { Reset } from 'styled-reset';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  Box, Button, CircularProgress, Container, Typography, styled, ThemeProvider,
} from '@mui/material';
import { theme } from './style/style';
import { AuthenticatedApp } from './authentication/components/authenticated-app';
import './style/css/satoshi.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function Authentication() {
  const {
    isAuthenticated,
    isLoading,
    error,
    loginWithRedirect,
    logout,
  } = useAuth0<{
    sub: any;
    name: string
  }>();

  if (isLoading) {
    return (
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '70vh',
        }}
      >
        <CircularProgress size={80} />
        <Typography variant="h6" paddingTop={15}>Chargement ...</Typography>
        {error && <Typography variant="h6" paddingTop={5}>{`erreur : ${error}`}</Typography>}
      </Container>
    );
  }
  if (error) {
    console.log({ error });
    return (
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h1" paddingTop={10} paddingBottom={10}>Oops...</Typography>
        <Typography variant="h6" paddingBottom={15}>{error?.message}</Typography>
        <Button
          sx={{
            color: 'background.paper',
          }}
          color="primary"
          variant="contained"
          onClick={() => logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })}
        >
          Déconnexion
        </Button>
      </Container>
    );
  }

  if (isAuthenticated) {
    return (
      <AuthenticatedApp />
    );
  }

  return (
    <>
      {loginWithRedirect()}
    </>
  );
}

function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ''}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
      scope="email profile openid"
    >
      <ThemeProvider theme={theme}>
        <Reset />
        <GlobalContainer>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider variant="error" autoHideDuration={5000}>
              <Authentication />
            </SnackbarProvider>
          </QueryClientProvider>
        </GlobalContainer>
      </ThemeProvider>
    </Auth0Provider>
  );
}

const GlobalContainer = styled(Box)(() => ({
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  scrollBehavior: 'smooth',
  webkitFontSmoothing: 'antialiased',
}));

export default App;
