import {
  Box, Button, Chip, Grid, Paper, Slider, TextField, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import rentabilite from '../../../assets/simulation/rentabilite.png';
import prix from '../../../assets/simulation/price.png';
import prix_a_negocier from '../../../assets/simulation/prix_a_negocier.png';
import { useUpdateProperty } from '../../../hooks/mutate/update-property-mutation.hook';
import { euro } from '../../../common/inputs/format/euro-format';
import { NumberFormatCustom } from '../../../common/inputs/format/number-format-custom';

function SimulationContainer({ queryInfo, simulationColor }: any) {
  const [searchParams] = useSearchParams();
  const [lsiExpectedPriceProfitability, setLsiExpectedPriceProfitability] = useState<any>(queryInfo?.data?.getPropertiesById?.finance?.lsi_profitability);
  const [lsiTotalBudgetProfitability, setLsiTotalBudgetProfitability] = useState<any>(lsiExpectedPriceProfitability);
  const [expectedPrice, setExpectedPrice] = useState<any>(queryInfo?.data?.getPropertiesById?.finance?.expected_price);
  const [toModify, setToModify] = useState<any>(false);

  const rent = !queryInfo?.data?.getPropertiesById?.description?.expected_rent
  || queryInfo?.data?.getPropertiesById?.description?.expected_rent === 0
    ? queryInfo?.data?.getPropertiesById?.description?.current_rent
    : queryInfo?.data?.getPropertiesById?.description?.expected_rent;

  const tax = queryInfo?.data?.getPropertiesById?.description?.tax;
  const furniture = queryInfo?.data?.getPropertiesById?.renovation?.furniture_cost;
  const renov = queryInfo?.data?.getPropertiesById?.renovation?.renovation_cost;

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const deltaVar = ((queryInfo?.data?.getPropertiesById?.description?.upfront_price - queryInfo?.data?.getPropertiesById?.finance?.expected_price) / queryInfo?.data?.getPropertiesById?.description?.upfront_price * 100).toFixed(1);

  return (
    <Box>
      <Paper sx={{
        boxShadow: 'none',
        border: 'solid 0.1px black',
        display: 'flex',
        padding: 2,
        bgcolor: 'background.paper',
        flexDirection: 'column',
        borderRadius: '5px !important',
        marginBottom: 3,
        ':before': {
          content: 'none',
        },
      }}
      >
        <Typography
          variant="h5"
          color="text.primary"
          fontWeight="bold"
        >
          Simulation financière
        </Typography>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          padding: 2,
          bgcolor: 'background.paper',
          flexDirection: 'column',
          borderRadius: { xs: '5px !important', sm: '10px !important' },
          marginBottom: 3,
          ':before': {
            content: 'none',
          },
        }}
      >
        <Grid
          container
          columns={2}
          display="flex"
          width="auto"
          height="40px"
        >
          <Grid item xs={1} display="flex" alignItems="center">
            <Box
              component="img"
              src={rentabilite}
              width="25px"
              sx={{ objectFit: 'contain', marginRight: '15px' }}
            />
            <Typography fontWeight="bold" variant="h6">
              Rentabilité
            </Typography>
          </Grid>
          <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
            <Typography
              textAlign="right"
              fontWeight="bold"
              variant="h6"
              sx={{ color: lsiTotalBudgetProfitability?.toFixed(1) >= 5 ? simulationColor?.green : simulationColor?.red }}
            >
              {`${lsiTotalBudgetProfitability?.toFixed(1)}%`}
            </Typography>
          </Grid>
        </Grid>
        <Box height="60px">
          <Slider
            step={0.1}
            value={lsiExpectedPriceProfitability}
            onChange={(e, value) => {
              setLsiExpectedPriceProfitability(value);
              const expectedPriceTmp = ((rent * 12) - tax) * 100 / (lsiExpectedPriceProfitability ?? 1);
              const notaryFees = expectedPriceTmp * 0.07;
              const lsiFees = (expectedPriceTmp + furniture + renov) * 0.12;
              const total = expectedPriceTmp + furniture + renov + notaryFees + lsiFees;
              const LsiTotalBudgetProfitabilityTmp = ((rent * 12) - tax) * 100 / (total ?? 1);
              setExpectedPrice(expectedPriceTmp);
              setLsiTotalBudgetProfitability(LsiTotalBudgetProfitabilityTmp);
            }}
            min={2}
            max={12}
          />
        </Box>
        <Grid
          container
          columns={2}
          display="flex"
          width="auto"
          height="60px"
        >
          <Grid item xs={1} display="flex" alignItems="center">
            <Box
              component="img"
              src={prix}
              width="25px"
              sx={{ objectFit: 'contain', marginRight: '15px' }}
            />
            <Typography fontWeight="bold" variant="h6">
              Prix affiché
            </Typography>
          </Grid>
          <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
            <Typography
              textAlign="right"
              fontWeight="bold"
              variant="h6"
              color="primary"
            >
              {euro.format(queryInfo?.data?.getPropertiesById?.description?.upfront_price)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          columns={2}
          display="flex"
          width="auto"
          height="60px"
        >
          <Grid item xs={1} display="flex" alignItems="center">
            <Box
              component="img"
              src={prix_a_negocier}
              width="25px"
              sx={{ objectFit: 'contain', marginRight: '15px' }}
            />
            <Typography fontWeight="bold" variant="h6">
              Prix à négocier
            </Typography>
            <Chip
              sx={{
                marginLeft: 1,
                display: { xs: 'none', sm: 'flex' },
              }}
              size="small"
              label={`${Math.abs(Number(deltaVar))}% ${Number(deltaVar) >= 0 ? 'Moins cher' : 'Plus cher'}`}
            />
          </Grid>
          <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
            {toModify
              ? (
                <Box
                  width={{ xs: '100%', sm: toModify ? '310px' : '100%' }}
                  flexDirection={{ xs: 'column', sm: toModify ? 'row' : 'column' }}
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <TextField
                    value={`${expectedPrice}` || ''}
                    sx={{ width: '150px' }}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                    size="small"
                    fullWidth
                    onChange={(e) => {
                      setExpectedPrice(Number(e.target.value));
                    }}
                  />
                  <Button
                    sx={{
                      width: '150px', textTransform: 'none', height: '40px', marginTop: { xs: 1, sm: 0 },
                    }}
                    variant="outlined"
                    onClick={() => {
                      const notaryFees = expectedPrice * 0.07;
                      const lsiFees = (expectedPrice + furniture + renov) * 0.12;
                      const lsiExpectedPriceProfit = ((rent * 12) - tax) * 100 / (expectedPrice ?? 1);
                      const total = expectedPrice + furniture + renov + notaryFees + lsiFees;
                      const totalBudgetProfitability = ((rent * 12) - tax) * 100 / (total ?? 1);
                      setLsiExpectedPriceProfitability(lsiExpectedPriceProfit);
                      setLsiTotalBudgetProfitability(totalBudgetProfitability);
                      setToModify(false);
                    }}
                  >
                    Enregistrer
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Typography
                    textAlign="right"
                    fontWeight="bold"
                    variant="h6"
                    sx={{ color: lsiTotalBudgetProfitability?.toFixed(1) >= 5 ? simulationColor?.green : simulationColor?.red }}
                  >
                    {euro.format(expectedPrice)}
                  </Typography>
                  <Chip
                    sx={{
                      display: { xs: 'flex', sm: 'none' },
                    }}
                    size="small"
                    label={`${Math.abs(Number(deltaVar))}% ${Number(deltaVar) >= 0 ? 'Moins cher' : 'Plus cher'}`}
                  />
                </Box>
              )}
          </Grid>
        </Grid>
        <Box
          display="flex"
          alignItems="center"
          marginTop={2}
          justifyContent={{ xs: 'space-between', md: 'flex-end' }}
        >
          <Button
            onClick={() => (toModify ? setToModify(false) : setToModify(true))}
            variant="outlined"
            color={toModify ? 'error' : 'primary'}
            sx={{ textTransform: 'none', marginRight: toModify ? 0 : 1 }}
          >
            {toModify ? 'Annuler' : 'Modifier le prix à négocier'}
          </Button>
          {!toModify
            && (
              <Button
                variant="contained"
                disabled={Number(queryInfo?.data?.getPropertiesById?.finance?.expected_price) === Number(expectedPrice)}
                sx={{ color: 'text.secondary', textTransform: 'none' }}
                onClick={() => {
                  const formValid: any = {
                    propertyId: searchParams.get('propertyId'),
                    data: {
                      finance: {
                        expected_price: Number(expectedPrice),
                      },
                    },
                  };
                  mutate({ ...formValid });
                }}
              >
                Enregistrer
              </Button>
            )}
        </Box>
      </Paper>
    </Box>
  );
}

export {
  SimulationContainer,
};
