import {
  Box,
} from '@mui/material';
import React from 'react';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useDeviceSize } from '../../../hooks/utils/device-size.hook';
import { euro } from '../../../common/inputs/format/euro-format';

ChartJS.register(ArcElement, Tooltip, Legend);

function GlobalBudget({ queryInfo, simulationColor }: any) {
  const { ipadSize } = useDeviceSize();
  const data = queryInfo?.data?.getPropertiesById;

  const datasets = {
    labels: [
      `Prix à négocier ${euro.format(data?.finance?.expected_price)}`,
      `Travaux ${euro.format(data?.renovation?.renovation_cost)}`,
      `Meubles ${euro.format(data?.renovation?.furniture_cost)}`,
      `Frais notaire ${euro.format(data?.finance?.notary_fees)}`,
      `Frais LSI ${euro.format(data?.finance?.lsi_fees)}`],
    total: queryInfo?.data?.getPropertiesById?.finance?.total_budget,
    datasets: [{
      data: [
        data?.finance?.expected_price,
        data?.renovation?.renovation_cost,
        data?.renovation?.furniture_cost,
        data?.finance?.notary_fees,
        data?.finance?.lsi_fees,
      ],
      backgroundColor: [
        simulationColor.pie1,
        simulationColor.pie2,
        simulationColor.pie3,
        simulationColor.pie4,
        simulationColor.pie5],
    }],
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={{ xs: '500px', sm: '300px' }} padding={{ xs: 1, sm: 3 }}>
      <Box width={{ xs: '100%', sm: '60%' }} height="100%">
        <Pie
          data={datasets}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              tooltip: {
                enabled: false,
              },
              legend: {
                display: true,
                position: ipadSize ? 'right' : 'bottom',
                fullSize: true,
                labels: {
                  usePointStyle: true,
                  padding: ipadSize ? 25 : 20,
                  font: {
                    size: 16,
                  },
                },
              },
            },
          }}
        />
      </Box>
    </Box>
  );
}

export {
  GlobalBudget,
};
