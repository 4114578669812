import {
  AllowedCollectiveOwnershipWorks,
  AllowedDpeGes,
  AllowedExploitation,
  AllowedFlatType,
  AllowedInternetFiber,
  AllowedLandlordFees,
  AllowedLevelOfUrgency,
  AllowedOffer,
  AllowedOrientation,
  AllowedPropertyDocumentsLabels,
  AllowedPropertyState,
  AllowedPropertyStatus,
  AllowedPropertyType,
  AllowedSellingReason,
  AllowedTransportation,
} from '../api';

const propertyTypeOptions = [{
  label: 'Appartement',
  value: AllowedPropertyType.FLAT,
}, {
  label: 'Maison',
  value: AllowedPropertyType.HOUSE,
}, {
  label: 'Immeuble',
  value: AllowedPropertyType.BUILDING,
}, {
  label: 'Terrain',
  value: AllowedPropertyType.LAND,
}];

const exploitationOptions = [{
  label: 'Colocation',
  value: AllowedExploitation.FLAT_SHARE,
}, {
  label: 'Location nue',
  value: AllowedExploitation.EMPTY_RENTAL,
}, {
  label: 'Location meublée',
  value: AllowedExploitation.FURNISHED_RENTAL,
}, {
  label: 'Location saisonnière',
  value: AllowedExploitation.SEASONAL_RENTAL,
}, {
  label: 'Location pro',
  value: AllowedExploitation.PRO_RENTAL,
}];

const offersOptions = [{
  value: AllowedOffer.ETINCELLE,
  label: 'Étincelle',
}, {
  value: AllowedOffer.ENVOL,
  label: 'Envol',
}, {
  value: AllowedOffer.ETOILE,
  label: 'Étoile',
}];

const fileLabelOptionsInput = [{
  value: AllowedPropertyDocumentsLabels?.CO_OWNERSHIP_REGULATIONS,
  label: 'Règlement de copropriété',
}, {
  value: AllowedPropertyDocumentsLabels?.BUILDING_STATE_DATE,
  label: 'Prêt état daté ',
}, {
  value: AllowedPropertyDocumentsLabels?.DIAGNOSIS,
  label: 'Diagnostic',
}, {
  value: AllowedPropertyDocumentsLabels?.GENERAL_ASSEMBLY_NOTICE,
  label: 'PV AG',
}, {
  value: AllowedPropertyDocumentsLabels?.BUILDING_BLUEPRINT,
  label: 'Plan',
}, {
  value: AllowedPropertyDocumentsLabels?.MAINTENANCE_BOOKLET,
  label: 'Carnet d\'entretien',
}, {
  value: AllowedPropertyDocumentsLabels?.CONDOMINIUM_FEES,
  label: 'Charges de copropriété',
}, {
  value: AllowedPropertyDocumentsLabels?.HOUSING_TAX,
  label: 'Taxe foncière',
}, {
  value: AllowedPropertyDocumentsLabels?.PROPERTY_PICTURE,
  label: 'Image ou video',
}, {
  value: AllowedPropertyDocumentsLabels?.OTHERS,
  label: 'Autre',
}];

const orientationOptions = [{
  value: AllowedOrientation.NORTH,
  label: 'Nord',
}, {
  value: AllowedOrientation.EAST,
  label: 'Est',
}, {
  value: AllowedOrientation.SOUTH,
  label: 'Sud',
}, {
  value: AllowedOrientation.WEST,
  label: 'Ouest',
}, {
  value: AllowedOrientation.NORTH_EAST,
  label: 'Nord-Est',
}, {
  value: AllowedOrientation.SOUTH_EAST,
  label: 'Sud-Est',
}, {
  value: AllowedOrientation.SOUTH_WEST,
  label: 'Sud-Ouest',
}, {
  value: AllowedOrientation.NORTH_WEST,
  label: 'Nord-Ouest',
}];

const landlordFeesOptions = [{
  value: AllowedLandlordFees.COLD_WATER,
  label: 'Eau froide',
}, {
  value: AllowedLandlordFees.HOT_WATER,
  label: 'Eau chaude',
}, {
  value: AllowedLandlordFees.HEATING,
  label: 'Chauffage',
}, {
  value: AllowedLandlordFees.GAS,
  label: 'Gaz',
}];

const collectiveOwnershipWorksOptions = [{
  value: AllowedCollectiveOwnershipWorks.VOTED,
  label: 'Oui, votés',
}, {
  value: AllowedCollectiveOwnershipWorks.PAID,
  label: 'Oui, payés',
}, {
  value: AllowedCollectiveOwnershipWorks.DISCUSSED,
  label: 'Oui, discutés',
}];

const flatTypeOptions = [{
  value: AllowedFlatType.T1,
  label: 'T1',
}, {
  value: AllowedFlatType.T2,
  label: 'T2',
}, {
  value: AllowedFlatType.T3,
  label: 'T3',
}, {
  value: AllowedFlatType.T4,
  label: 'T4',
}, {
  value: AllowedFlatType.T5,
  label: 'T5',
}, {
  value: AllowedFlatType.T6,
  label: 'T6',
}, {
  value: AllowedFlatType.T7,
  label: 'T7',
}, {
  value: AllowedFlatType.T8,
  label: 'T8',
}, {
  value: AllowedFlatType.T9,
  label: 'T9',
}, {
  value: AllowedFlatType.T10,
  label: 'T10',
}];

const dpeGesOptions = [{
  value: AllowedDpeGes.A,
  label: 'A',
}, {
  value: AllowedDpeGes.B,
  label: 'B',
}, {
  value: AllowedDpeGes.C,
  label: 'C',
}, {
  value: AllowedDpeGes.D,
  label: 'D',
}, {
  value: AllowedDpeGes.E,
  label: 'E',
}, {
  value: AllowedDpeGes.F,
  label: 'F',
}, {
  value: AllowedDpeGes.G,
  label: 'G',
},
];

const sellingReasonOptions = [{
  value: AllowedSellingReason.DEATHS,
  label: 'Décès',
}, {
  value: AllowedSellingReason.SEPARATION,
  label: 'Séparation',
}, {
  value: AllowedSellingReason.MOVING_OUT,
  label: 'Déménagement',
}, {
  value: AllowedSellingReason.DIVORCE,
  label: 'Divorce',
}, {
  value: AllowedSellingReason.FINANCIAL,
  label: 'Financière',
}, {
  value: AllowedSellingReason.OTHER,
  label: 'Autre',
}];

const transportationOptions = [{
  value: AllowedTransportation.SUBWAY,
  label: '(M) Métro de Paris',
}, {
  value: AllowedTransportation.RER,
  label: '(RER) RER d’Île-de-France',
}, {
  value: AllowedTransportation.TRANSILIEN,
  label: 'Transilien',
}, {
  value: AllowedTransportation.TRAMWAY,
  label: '(T) Tramway d’Île-de-France',
}, {
  value: AllowedTransportation.AUTOBUS,
  label: 'Autobus d’Île-de-France',
}];

const propertyStatusOptions = [{
  value: AllowedPropertyStatus.NEW_PROPERTY,
  label: 'Nouveau bien',
  index: 0,
}, {
  value: AllowedPropertyStatus.QUALIFIED_PROPERTY,
  label: 'Bien qualifié',
  index: 1,
}, {
  value: AllowedPropertyStatus.PROPERTY_UNDER_NEGOTIATION,
  label: 'Bien en négociation',
  index: 2,
}, {
  value: AllowedPropertyStatus.ACCEPTED_OFFER,
  label: 'Offre Acceptée',
  index: 3,
}, {
  value: AllowedPropertyStatus.PENDING_MATCHING,
  label: 'Matching en cours',
  index: 4,
}, {
  value: AllowedPropertyStatus.PROPERTY_MATCHED,
  label: 'Bien Matché',
  index: 5,
}, {
  value: AllowedPropertyStatus.WAITING_TO_BE_MATCHED,
  label: 'En attente d‘être matché',
  index: 6,
}, {
  value: AllowedPropertyStatus.CLOSED,
  label: 'Fermé',
  index: 7,
}];

const levelOfUrgencyOptions = [{
  value: AllowedLevelOfUrgency.UNDER_48H,
  label: 'Moins de 48h',
  color: (theme: any) => theme?.palette?.error.main,
}, {
  value: AllowedLevelOfUrgency.BETWEEN_48_AND_A_WEEK,
  label: 'Entre 2j et une sem.',
  color: (theme: any) => theme?.palette?.warning.main,
}, {
  value: AllowedLevelOfUrgency.MORE_THAN_A_WEEK,
  label: "Plus d'une sem.",
  color: (theme: any) => theme?.palette?.success.main,
}];

const stateOptions = [{
  value: AllowedPropertyState.IN_PROGRESS,
  label: 'En cours',
}, {
  value: AllowedPropertyState.MATCHED,
  label: 'Matchés',
}, {
  value: AllowedPropertyState.CLOSED,
  label: 'Fermés',
}];

const internetFiberOptions = [{
  value: AllowedInternetFiber?.YES,
  label: 'Oui',
}, {
  value: AllowedInternetFiber?.NO,
  label: 'Non',
}, {
  value: AllowedInternetFiber?.YES_IN_PROPERTY,
  label: 'Oui dans l‘immeuble',
}];

export {
  flatTypeOptions,
  dpeGesOptions,
  sellingReasonOptions,
  levelOfUrgencyOptions,
  propertyTypeOptions,
  orientationOptions,
  collectiveOwnershipWorksOptions,
  landlordFeesOptions,
  transportationOptions,
  offersOptions,
  exploitationOptions,
  propertyStatusOptions,
  fileLabelOptionsInput,
  stateOptions,
  internetFiberOptions,
};
