import React, { useEffect, useMemo } from 'react';
import {
  Box, Button, Grid, IconButton,
} from '@mui/material';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import AddressCustom from '../../../common/inputs/autocompleteadress';
import {
  AccordionContainer, GridContainerFormInput, SectionTitle, SubmitButtonForm,
} from '../../components/containerform';
import { Carousel } from './carousel';
import { Transportation } from './transportation';
import { FutureTransportations } from './future-transportations';
import { transportationOptions } from '../../../data/data';
import { useUpdateProperty } from '../../../hooks/mutate/update-property-mutation.hook';

interface IFormInput {
  street_address: string
  address_details: string
}

function Summary({
  indexAccordion, setIndexAccordion, index, indexMax, data, queryInfo, title,
}: any) {
  const [searchParams] = useSearchParams();

  const format = {
    ...data,
    localisation: {
      ...data?.localisation,
      transportations: data?.localisation?.transportations?.map((e: any) => ({
        transportation: transportationOptions?.find((f) => f.value === e.transportation),
        transportation_detail: e?.transportation_detail,
        walking_time: Number(e?.walking_time),
      })),
      futureTransportations: data?.localisation?.futureTransportations?.map((e: any) => ({
        transportation: transportationOptions?.find((f) => f.value === e.transportation),
        transportation_detail: e?.transportation_detail,
        walking_time: Number(e?.walking_time),
        releaseDate: e?.releaseDate,
      })),
    },
    ad_link: data?.ad_link?.map((e: any) => ({ link: e })),
  };

  const methods = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return { ...format };
    }, [data, searchParams.get('propertyId')]),
  });
  const { control, reset, handleSubmit } = methods;

  useEffect(() => {
    reset({ ...format });
  }, [queryInfo.isSuccess, searchParams.get('propertyId')]);

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'ad_link',
  });

  const defaultValue = {
    link: null,
  };

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: (resp: any) => {
      queryInfo.refetch();
      // eslint-disable-next-line no-param-reassign
      setIndexAccordion(indexAccordion += 1);
      // eslint-disable-next-line no-param-reassign
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        ad_link: form?.ad_link?.map((e: any) => e?.link),
        localisation: {
          street_address: {
            address_line_1: form?.localisation?.street_address?.address_line_1,
            country: form?.localisation?.street_address?.country,
            locality: form?.localisation?.street_address?.locality,
            zipcode: form?.localisation?.street_address?.zipcode,
            region: form?.localisation?.street_address?.region || '',
          },
          transportations: form?.localisation?.transportations?.map((e: any) => ({
            transportation: e?.transportation?.value,
            transportation_detail: e?.transportation_detail,
            walking_time: Number(e?.walking_time),
          })),
          futureTransportations: form?.localisation?.futureTransportations?.map((e: any) => ({
            transportation: e?.transportation?.value,
            transportation_detail: e?.transportation_detail,
            walking_time: Number(e?.walking_time),
            releaseDate: e?.releaseDate,
          })),
          address_details: form?.localisation?.address_details,
        },
      },
    };
    mutate({ ...formValid });
  };

  return (
    <AccordionContainer
      title={title}
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
      index={index}
    >
      <FormProvider {...methods}>
        <Carousel queryInfo={queryInfo} />
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box paddingX={{ xs: 0, md: 7 }}>
            <Box>
              <GridContainerFormInput
                xsCol={1}
                smCol={2}
              >
                <Grid item xs={2}>
                  <AddressCustom
                    size="small"
                    control={control}
                    name="localisation.street_address"
                    label="Adresse du bien"
                  />
                  <TextFieldCustom
                    size="small"
                    control={control}
                    name="localisation.address_details"
                    placeholder="Bâtiment, porte, cage d'escalier..."
                    label="Complément d’adresse"
                  />
                  <SectionTitle text="Lien de l’annonce" />
                  {fields?.map((item, indexField) => (
                    <Box key={item + indexField.toString()} display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                      <Box width="100%" marginRight={{ xs: 0, md: 2 }}>
                        <TextFieldCustom
                          size="small"
                          control={control}
                          name={`ad_link[${indexField}].link`}
                        />
                      </Box>
                      <Button
                        sx={{
                          display: { xs: 'inherit', sm: 'none' },
                          padding: 0,
                          minWidth: 0,
                          marginTop: -3,
                          marginBottom: 2,
                          width: '100%',
                          height: 40,
                          color: 'text.secondary',
                        }}
                        variant="contained"
                        color="primary"
                        onClick={() => remove(indexField)}
                      >
                        <ClearIcon />
                      </Button>
                      <IconButton
                        sx={{
                          display: { xs: 'none', sm: 'inherit' },
                          height: 40,
                          width: 40,
                        }}
                        onClick={() => remove(indexField)}
                        color="error"
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <Box display="flex" marginBottom={2}>
                    <Button
                      type="button"
                      sx={{
                        cursor: 'pointer',
                        textTransform: 'none',
                        textDecoration: 'underline',
                        marginBottom: { xs: 3, sm: 0 },
                        transition: 'opacity 0.3s',
                        fontSize: '16px',
                        width: 'fit-content',
                        ':hover': {
                          opacity: 0.8,
                        },
                      }}
                      startIcon={<AddCircleIcon />}
                      color="primary"
                      onClick={() => append(defaultValue)}
                    >
                      Ajouter un lien
                    </Button>
                  </Box>
                  <Transportation />
                  <FutureTransportations />
                </Grid>
              </GridContainerFormInput>
              <SubmitButtonForm index={index} indexMax={indexMax} />
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </AccordionContainer>
  );
}

export {
  Summary,
};
