import {
  Box, Typography,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import { MuiTelInput } from 'mui-tel-input';

function PhoneCustom({
  setCountry, control, name, label, rules, paddingBottom = '45px',
}: any) {
  // eslint-disable-next-line consistent-return
  function checkValue(value: any) {
    if (value[1] === '0') {
      return '33';
    }
    return value;
  }
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <Box paddingBottom={paddingBottom}>
          <MuiTelInput
            {...field}
            value={field.value ? checkValue(field.value) : null}
            onChange={(e: any, country) => {
              field.onChange(e);
              setCountry(country);
            }}
            placeholder={label}
            size="small"
            fullWidth
          />
          {fieldState?.error?.message
            && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
        </Box>
      )}
    />
  );
}

export {
  PhoneCustom,
};
