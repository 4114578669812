import {
  alpha,
  Box, Grid, Typography,
} from '@mui/material';
import React from 'react';
import TimerIcon from '@mui/icons-material/Timer';
import status from '../../../assets/simulation/status.png';
import location from '../../../assets/simulation/location.png';
import surface from '../../../assets/simulation/surface.png';
import { levelOfUrgencyOptions, propertyTypeOptions } from '../../../data/data';
import flat from '../../../assets/property/appartement.png';
import house from '../../../assets/property/maison.png';
import building from '../../../assets/property/immeuble.png';
import land from '../../../assets/property/terrain.png';
import { AllowedPropertyType } from '../../../api';
import prix_a_negocier from '../../../assets/simulation/prix_a_negocier.png';
import rentabilite from '../../../assets/simulation/rentabilite.png';
import { euro } from '../../../common/inputs/format/euro-format';

function SummarySimulation({ queryInfo, simulationColor }: any) {
  const data = queryInfo?.data?.getPropertiesById;

  const chooseTypeOfProperty = (type: any) => {
    switch (type) {
      case AllowedPropertyType.FLAT: {
        return flat;
      }
      case AllowedPropertyType.HOUSE: {
        return house;
      }
      case AllowedPropertyType.BUILDING: {
        return building;
      }
      case AllowedPropertyType.LAND: {
        return land;
      }
      default: {
        return flat;
      }
    }
  };

  return (
    <Box marginBottom={2}>
      <Grid
        container
        item
        columns={{ xs: 2, sm: 5 }}
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        marginY={1}
      >
        <Grid item xs={1} display="flex" justifyContent="center" alignItems="center" paddingY={2}>
          <Box
            component="img"
            sx={{
              display: 'block',
              objectFit: 'cover',
              height: '25px',
              marginRight: '15px',
            }}
            src={chooseTypeOfProperty(data?.type)}
          />
          <Typography>{propertyTypeOptions?.find((f) => f.value === data?.type)?.label}</Typography>
        </Grid>
        {data?.localisation?.street_address?.locality && data?.localisation?.street_address?.zipcode
          && (
            <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" paddingY={2}>
              <Box
                component="img"
                sx={{
                  display: 'block',
                  objectFit: 'cover',
                  height: '35px',
                  marginRight: '15px',
                }}
                src={location}
              />
              <Typography>{`${data?.localisation?.street_address?.locality} ${data?.localisation?.street_address?.zipcode}`}</Typography>
            </Grid>
          )}
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" paddingY={2}>
          <Box
            component="img"
            sx={{
              display: 'block',
              objectFit: 'cover',
              height: '25px',
              marginRight: '15px',
            }}
            src={surface}
          />
          <Typography>{`${data?.description?.surface}m2`}</Typography>
        </Grid>
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" paddingY={2}>
          <Box
            component="img"
            sx={{
              display: 'block',
              objectFit: 'cover',
              height: '25px',
              marginRight: '15px',
            }}
            src={status}
          />
          <Typography>Status</Typography>
        </Grid>
        {data?.conclusion?.level_of_urgency
          && (
            <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" paddingY={2}>
              <TimerIcon sx={{
                height: '25px',
                marginRight: '15px',
                color: levelOfUrgencyOptions.find((f) => f.value === data?.conclusion?.level_of_urgency)?.color,
              }}
              />
              <Typography
                color={levelOfUrgencyOptions.find((f) => f.value === data?.conclusion?.level_of_urgency)?.color}
              >
                {levelOfUrgencyOptions.find((f) => f.value === data?.conclusion?.level_of_urgency)?.label}
              </Typography>
            </Grid>
          )}
      </Grid>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        marginY={{ xs: 1, sm: 3 }}
      >
        <BoxDetails
          icon={prix_a_negocier}
          title="Prix à négocier"
          simulationColor={simulationColor}
          value={data?.finance?.lsi_profitability}
          displayedValue={euro.format(data?.finance?.expected_price)}
        />
        <BoxDetails
          icon={rentabilite}
          title="Rentabilité"
          simulationColor={simulationColor}
          value={data?.finance?.lsi_profitability}
          displayedValue={`${data?.finance?.lsi_profitability?.toFixed(1)}%`}
        />
      </Box>
    </Box>
  );
}

function BoxDetails({
  icon, title, displayedValue, simulationColor, value,
}:any) {
  return (
    <Box
      sx={{
        borderRadius: '5px',
        border: '0.1px solid',
        borderColor: alpha('#000000', 0.1),
      }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingY={2}
      paddingX={4}
      marginBottom={{ xs: 3, sm: 0 }}
      width={{ xs: '80%', sm: '40%' }}
    >
      <Box
        display="flex"
        marginRight={4}
        alignItems="center"
      >
        <Box
          component="img"
          src={icon}
          width="25px"
          sx={{ objectFit: 'contain', marginRight: '15px' }}
        />
        <Typography fontWeight="bold" variant="h6" fontSize={{ xs: '16px', sm: '20px' }}>
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography
          fontWeight="bold"
          variant="h6"
          fontSize={{ xs: '16px', sm: '20px' }}
          sx={{ color: value?.toFixed(1) >= 5 ? simulationColor?.green : simulationColor?.red }}
        >
          {displayedValue}
        </Typography>
      </Box>
    </Box>
  );
}

export {
  SummarySimulation,
};
