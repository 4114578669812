import {
  Box, Button, Grid, IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import { SectionTitle } from '../../components/containerform';
import { AutocompleteCustom } from '../../../common/inputs/autocompletecustom';
import { transportationOptions } from '../../../data/data';

function Transportation() {
  const {
    control,
  } = useFormContext<any>();

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'localisation.transportations',
  });

  const defaultValue = {
    transportation: null,
    transportation_detail: null,
    walking_time: null,
  };

  return (
    <Box marginTop={3}>
      <SectionTitle text="Transport" />
      {fields.map((item, indexField) => (
        <Box key={item + indexField.toString()} display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
          <Grid container columns={{ xs: 1, sm: 3 }} spacing={{ xs: 0, sm: 3 }} marginRight={{ xs: 0, md: 2 }}>
            <Grid item xs={1}>
              <AutocompleteCustom
                size="small"
                control={control}
                tab={transportationOptions}
                name={`localisation.transportations[${indexField}].transportation`}
                label="Type de transport proche"
              />
            </Grid>
            <Grid item xs={1}>
              <TextFieldCustom
                size="small"
                control={control}
                name={`localisation.transportations[${indexField}].transportation_detail`}
                label="Ligne de Transport"
              />
            </Grid>
            <Grid item xs={1}>
              <TextFieldCustom
                size="small"
                control={control}
                type="number"
                name={`localisation.transportations[${indexField}].walking_time`}
                label="Temps à pied jusqu’à l’arrêt (min)"
              />
            </Grid>
          </Grid>
          <Button
            sx={{
              display: { xs: 'inherit', sm: 'none' },
              padding: 0,
              minWidth: 0,
              marginTop: -3,
              marginBottom: 2,
              width: '100%',
              height: 40,
              color: 'text.secondary',
            }}
            variant="contained"
            color="primary"
            onClick={() => remove(indexField)}
          >
            <ClearIcon />
          </Button>
          <IconButton
            sx={{
              display: { xs: 'none', sm: 'inherit' },
              height: 40,
              width: 40,
            }}
            onClick={() => remove(indexField)}
            color="error"
          >
            <RemoveCircleIcon />
          </IconButton>
        </Box>
      ))}
      <Box display="flex">
        <Button
          type="button"
          sx={{
            cursor: 'pointer',
            textTransform: 'none',
            textDecoration: 'underline',
            marginBottom: { xs: 3, sm: 0 },
            transition: 'opacity 0.3s',
            fontSize: '16px',
            width: 'fit-content',
            ':hover': {
              opacity: 0.8,
            },
          }}
          startIcon={<AddCircleIcon />}
          color="primary"
          onClick={() => append(defaultValue)}
        >
          Ajouter transport
        </Button>
      </Box>
    </Box>
  );
}

export {
  Transportation,
};
