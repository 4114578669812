import React, { useEffect, useState } from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { Text } from '../pages';
import { AddAgency } from './add-agency';
import { AddAgent } from './add-agent';
import { useGetAllRealEstateAgencies } from '../../../hooks/query/get-all-real-estate-agencies-query.hook';

function FormRight({ control, watch, setValue }: any) {
  const [openAddAgency, setOpenAddAgency] = useState(false);
  const [openAddAgent, setOpenAddAgent] = useState(false);
  const queryAgency = useGetAllRealEstateAgencies();

  useEffect(() => {
    setValue('realEstateAgent', null);
  }, [watch('realEstateAgency')]);

  return (
    <Box
      width={{ xs: '100%', md: '50%' }}
    >
      <Typography
        variant="h5"
        fontWeight={600}
        marginBottom={2}
        width="90%"
        textAlign="left"
      >
        Informations agence
        {' '}
        <Text>
          immobilière
        </Text>
      </Typography>
      <AddAgency
        control={control}
        queryAgency={queryAgency}
        openAddAgency={openAddAgency}
        setOpenAddAgency={setOpenAddAgency}
        setOpenAddAgent={setOpenAddAgent}
        setValue={setValue}
      />
      {
        watch('realEstateAgency')
        && (
          <AddAgent
            queryAgency={queryAgency}
            control={control}
            watch={watch}
            openAddAgent={openAddAgent}
            setOpenAddAgent={setOpenAddAgent}
            setOpenAddAgency={setOpenAddAgency}
            setValue={setValue}
          />
        )
      }
    </Box>
  );
}

export {
  FormRight,
};
