import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import { Controller } from 'react-hook-form';

function RichTextCustom({ control, name }: any) {
  return (
    <Grid item>
      <Controller
        name={name}
        control={control}
        render={({
          field,
        }) => {
          return (
            <Box width="100%">
              <RichText
                value={field.value || ''}
                onChange={(e: any) => field.onChange(e.target.value)}
                placeholder="Écrivez ici ..."
              />
            </Box>
          );
        }}
      />
    </Grid>
  );
}

export {
  RichTextCustom,
};

const RichText = styled('textarea')(({ theme }) => ({
  borderRadius: '5px',
  height: '85px',
  padding: '0px',
  display: 'flex',
  paddingLeft: '14px',
  paddingRight: '14px',
  paddingTop: '14px',
  paddingBottom: '14px',
  width: 'calc(100% - 31px);',
  fontSize: '14px',
  letterSpacing: '2px',
  color: theme.palette.text.primary,
  resize: 'none',
  border: '2px solid',
  borderColor: theme.palette.primary.main,
  ':focus-visible ': {
    outline: 'none',
  },
  fontFamily: [
    'Satoshi-Variable',
    'Satoshi-VariableItalic',
    'Satoshi-Light',
    'Satoshi-LightItalic',
    'Satoshi-Regular',
    'Satoshi-Italic',
    'Satoshi-Medium',
    'Satoshi-MediumItalic',
    'Satoshi-Bold',
    'Satoshi-BoldItalic',
    'Satoshi-Black',
    'Satoshi-BlackItalic',
    'Poppins',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
}));
