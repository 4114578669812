import { Box, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import { AutocompleteCustom } from '../../../common/inputs/autocompletecustom';
import { AddAgentContainer } from '../../../common/agency/add-input';

function AddAgent({
  control, openAddAgent, setOpenAddAgent, setOpenAddAgency, watch, queryAgency, setValue,
}: any) {
  const tabAgent = queryAgency?.data?.getAllRealEstateAgencies.find((f: any) => f._id === watch('realEstateAgency')?.value)?.realEstateAgents?.map((e: any) => ({
    value: e?._id,
    label: e?.name,
  }));

  return (
    <Box width={{ xs: '100%', md: 500 }} marginTop={2}>
      {!openAddAgent && tabAgent
        && (
          <AutocompleteCustom
            size="small"
            tab={tabAgent || []}
            control={control}
            label="Veuillez sélectionner un agent immobilier"
            name="realEstateAgent"
          />
        )}
      {!openAddAgent
        && (
          <Button
            type="button"
            sx={{
              marginTop: -4,
              cursor: 'pointer',
              textTransform: 'none',
              textDecoration: 'underline',
              transition: 'opacity 0.3s',
              fontSize: '16px',
              width: 'fit-content',
              ':hover': {
                opacity: 0.8,
              },
            }}
            startIcon={<AddCircleIcon />}
            color="primary"
            onClick={() => {
              setOpenAddAgent(!openAddAgent);
              setOpenAddAgency(false);
            }}
          >
            Ajouter un agent immobilier
          </Button>
        )}
      {openAddAgent
        ? (
          <AddAgentContainer
            setOpen={setOpenAddAgent}
            agencyId={watch('realEstateAgency')?.value}
            queryAgency={queryAgency}
            setValue={setValue}
          />
        )
        : null}
    </Box>
  );
}

export {
  AddAgent,
};
