import React, { useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import buanderie from '../../../assets/arrangement/buanderie.png';
import chambre from '../../../assets/arrangement/chambre.png';
import salle_de_bain from '../../../assets/arrangement/salle_de_bain.png';
import wc from '../../../assets/arrangement/wc.png';
import { AddOrRemoveField } from '../../../common/inputs/addorremovefield';
import { useUpdateProperty } from '../../../hooks/mutate/update-property-mutation.hook';
import { AccordionContainer, GridContainerFormInput, SubmitButtonForm } from '../../components/containerform';

interface IFormInput {
  bedroom_number: number
  bathroom_number: number
  laundry_room_number: number
  water_closet_number: number
}

function FutureLayout({
  indexAccordion, setIndexAccordion, index, indexMax, title, data, queryInfo,
}: any) {
  const [searchParams] = useSearchParams();
  const format = {
    bedroom_number: data?.bedroom_number || 0,
    bathroom_number: data?.bathroom_number || 0,
    laundry_room_number: data?.laundry_room_number || 0,
    water_closet_number: data?.water_closet_number || 0,
  };

  const {
    control, handleSubmit, watch, reset,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return { ...format };
    }, [data]),
  });

  useEffect(() => {
    reset({ ...format });
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProperty({
    onSuccess: () => {
      queryInfo.refetch();
      // eslint-disable-next-line no-param-reassign
      setIndexAccordion(indexAccordion += 1);
      // eslint-disable-next-line no-param-reassign
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const onSubmit = (form: any): any => {
    const formValid: any = {
      propertyId: searchParams.get('propertyId'),
      data: {
        arrangement: {
          futureLayout: {
            bedroom_number: Number(form?.bedroom_number),
            bathroom_number: Number(form?.bathroom_number),
            laundry_room_number: Number(form?.laundry_room_number),
            water_closet_number: Number(form?.water_closet_number),
          },
        },
      },
    };
    mutate({ ...formValid });
  };

  return (
    <AccordionContainer
      title={title}
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
      index={index}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box paddingX={{ xs: 0, sm: 7 }}>
          <Box>
            <GridContainerFormInput
              xsCol={2}
              smCol={4}
            >
              <Grid item xs={1}>
                <AddOrRemoveField
                  control={control}
                  name="bedroom_number"
                  watch={watch}
                  label="Chambre"
                  icon={chambre}
                />
              </Grid>
              <Grid item xs={1}>
                <AddOrRemoveField
                  control={control}
                  name="bathroom_number"
                  watch={watch}
                  label="Salle de bain"
                  icon={salle_de_bain}
                />
              </Grid>
              <Grid item xs={1}>
                <AddOrRemoveField
                  control={control}
                  name="water_closet_number"
                  watch={watch}
                  label="WC"
                  icon={wc}
                />
              </Grid>
              <Grid item xs={1}>
                <AddOrRemoveField
                  control={control}
                  name="laundry_room_number"
                  watch={watch}
                  label="Buanderie"
                  icon={buanderie}
                />
              </Grid>
            </GridContainerFormInput>
          </Box>
          <SubmitButtonForm index={index} indexMax={indexMax} />
        </Box>
      </Box>
    </AccordionContainer>
  );
}

export {
  FutureLayout,
};
