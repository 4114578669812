import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import gif1 from '../../assets/gif/gif1.gif';
import gif2 from '../../assets/gif/gif2.gif';
import { useDeviceSize } from '../../hooks/utils/device-size.hook';

function Menu() {
  const navigate = useNavigate();
  const { ipadSize } = useDeviceSize();

  return (
    <Box
      width={{ xs: '90%', sm: '80%' }}
      height={{ xs: 'auto', sm: 'calc(100vh - 100px)' }}
      paddingY={{ xs: 5, sm: 0 }}
      flexDirection="column"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="h5"
        fontWeight={600}
        width={{ xs: '90%', sm: '80%' }}
        textAlign={{ xs: 'center', sm: 'left' }}
      >
        Bienvenue dans votre espace Chasseur
      </Typography>
      <Box
        marginTop="20px"
        onClick={() => navigate('form/add-property')}
        sx={{
          border: '0.1px solid black',
          cursor: 'pointer',
          borderRadius: '5px',
          transition: 'transform 0.3s',
        }}
        width={{ xs: '90%', sm: '80%' }}
        height={{ xs: 350, sm: 200 }}
        marginBottom={3}
        bgcolor="background.paper"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box width={{ xs: '90%', sm: '20%' }} display="flex" alignItems="center" justifyContent="center">
          <Box
            component="img"
            src={gif1}
            width={{ xs: 100, sm: 150 }}
            sx={{ objectFit: 'cover' }}
          />
        </Box>
        <Box width={{ xs: '90%', sm: '40%' }}>
          <Typography marginBottom={{ xs: 3, sm: 0 }} fontWeight="bold" variant="h4">
            <Text>
              Ajouter
            </Text>
            {' '}
            un bien
          </Typography>
          <Typography fontWeight="bold" marginTop={1} variant="body1">
            Vous souhaitez renseigner un nouveau bien
            immobilier
          </Typography>
        </Box>
        <Box
          width={{ xs: '90%', sm: '20%' }}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: 'center', sm: 'flex-end' }}
          paddingRight={{ xs: 0, sm: 5 }}
        >
          <Box
            padding={0}
            paddingY={0}
            paddingX={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: 'primary.main',
              color: 'text.secondary',
              borderRadius: 45,
              filter: 'drop-shadow(0px 4px 4px rgba(26, 40, 54, 0.25))',
              padding: '18px',
              width: { xs: '40px', sm: '60px' },
              height: { xs: '40px', sm: '60px' },
              textTransform: 'none',
            }}
          >
            <ArrowForwardIosIcon fontSize={ipadSize ? 'large' : 'small'} />
          </Box>
        </Box>
      </Box>
      <Box
        onClick={() => navigate('/property-list')}
        sx={{
          border: '0.1px solid black',
          cursor: 'pointer',
          transition: 'transform 0.3s',
          borderRadius: '5px',
        }}
        width={{ xs: '90%', sm: '80%' }}
        height={{ xs: 350, sm: 200 }}
        marginTop={3}
        bgcolor="background.paper"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box width={{ xs: '90%', sm: '20%' }} display="flex" alignItems="center" justifyContent="center">
          <Box
            component="img"
            src={gif2}
            width={{ xs: 100, sm: 150 }}
            sx={{ objectFit: 'cover' }}
          />
        </Box>
        <Box width={{ xs: '90%', sm: '40%' }}>
          <Typography marginBottom={{ xs: 3, sm: 0 }} fontWeight="bold" variant="h4">
            <Text>
              Liste
            </Text>
            {' '}
            des biens
          </Typography>
          <Typography fontWeight="bold" marginTop={1} variant="body1">
            Vous souhaitez accéder à la liste de vos biens déjà renseignés
          </Typography>
        </Box>
        <Box
          width={{ xs: '90%', sm: '20%' }}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: 'center', sm: 'flex-end' }}
          paddingRight={{ xs: 0, sm: 5 }}
        >
          <Box
            padding={0}
            paddingY={0}
            paddingX={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: 'primary.main',
              color: 'text.secondary',
              padding: '18px',
              borderRadius: 45,
              filter: 'drop-shadow(0px 4px 4px rgba(26, 40, 54, 0.25))',
              width: { xs: '40px', sm: '60px' },
              height: { xs: '40px', sm: '60px' },
              textTransform: 'none',
            }}
          >
            <ArrowForwardIosIcon fontSize={ipadSize ? 'large' : 'small'} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const Text = styled('strong')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export {
  Menu,
};
