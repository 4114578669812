import React, { useState } from 'react';
import {
  Box,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Header } from '../header/header';
import { MaxWidthContainer } from '../../../utils/components/max-width-container';
import { Footer } from './footer';
import { useFindUserLoggedIn } from '../../../hooks/query/find-user-logged-in-query.hook';

function Layout({ children }: any) {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const {
    logout,
  } = useAuth0<{ sub: any; name: string }>();
  const queryUser = useFindUserLoggedIn();

  const menu = [{
    value: 'Ajouter un bien',
    url: 'form/add-property',
    page: 'form',
  }, {
    value: 'Liste de mes biens',
    url: 'property-list',
    page: 'property-list',
  }];

  return (
    <Box>
      <Box
        minHeight={{ xs: '100dvh', md: '100vh' }}
        width="100%"
        height="100%"
        position="relative"
      >
        <Header
          openMobileMenu={openMobileMenu}
          setOpenMobileMenu={setOpenMobileMenu}
          menu={menu}
          queryUser={queryUser}
          logout={logout}
        />
        <MaxWidthContainer>
          <Box
            display={{ xs: openMobileMenu ? 'none' : 'flex', md: 'flex' }}
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            {children}
          </Box>
        </MaxWidthContainer>
      </Box>
      <Footer
        menu={menu}
        queryUser={queryUser}
        logout={logout}
      />
    </Box>

  );
}

export {
  Layout,
};
