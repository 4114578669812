import { useCreatePropertyMutation } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useCreateProperty = (options: any) => {
  const { headerWithAuth } = useGetAccessToken();
  const { mutate } = useCreatePropertyMutation(headerWithAuth, options);

  return mutate;
};

export {
  useCreateProperty,
};
