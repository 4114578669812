import React from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  Box,
  Grid, styled, TextField, TextFieldProps, Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import frLocale from 'date-fns/locale/fr';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function CalendarCustom({
  control, name, label, rules = null, size = 'small', variant = 'outlined', maxDate, minDate,
}: any) {
  return (
    <Grid item height={80}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          return (
            <Box>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={frLocale}
              >
                <DesktopDatePicker
                  {...field}
                  value={field.value || null}
                  label={label}
                  onChange={(e: any) => {
                    field.onChange(e);
                  }}
                  maxDate={maxDate}
                  minDate={minDate}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                    <CssAutocomplete
                      {...params}
                      size={size}
                      fullWidth
                      variant={variant}
                    />
                  )}
                />
                {fieldState?.error?.message && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
              </LocalizationProvider>
            </Box>
          );
        }}
      />
    </Grid>
  );
}

const CssAutocomplete = styled(TextField)(({ theme }) => ({
  label: {
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
  input: {
    color: theme.palette.text.primary,
  },
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '.css-ke91x2-MuiInputBase-root-MuiInput-root:before': {
    borderBottomColor: theme.palette.primary.main,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.primary.main,
  },
  '.css-i4bv87-MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
  '.css-ptiqhd-MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
}));

export {
  CalendarCustom,
};
