import { Box, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import { AutocompleteCustom } from '../../../common/inputs/autocompletecustom';
import { AddAgencyContainer } from '../../../common/agency/add-input';

function AddAgency({
  control, openAddAgency, setOpenAddAgency, setOpenAddAgent, queryAgency, setValue,
} : any) {
  const tabAgency = queryAgency?.data?.getAllRealEstateAgencies.map((e: any) => ({
    value: e?._id,
    label: `${e?.realEstateAgencyName} - ${e?.realEstateAgencyAddress}`,
  }));

  return (
    <Box width={{ xs: '100%', md: 500 }}>
      {!openAddAgency && tabAgency
        && (
        <AutocompleteCustom
          size="small"
          tab={tabAgency || []}
          control={control}
          label="Veuillez sélectionner une agence immobilière"
          name="realEstateAgency"
        />
        )}
      {!openAddAgency
        && (
          <Button
            type="button"
            sx={{
              marginBottom: 2,
              marginTop: -4,
              cursor: 'pointer',
              textTransform: 'none',
              textDecoration: 'underline',
              transition: 'opacity 0.3s',
              fontSize: '16px',
              width: 'fit-content',
              ':hover': {
                opacity: 0.8,
              },
            }}
            startIcon={<AddCircleIcon />}
            color="primary"
            onClick={() => {
              setOpenAddAgency(!openAddAgency);
              setOpenAddAgent(false);
            }}
          >
            Ajouter une agence immobilière
          </Button>
        )}
      {openAddAgency
        ? (
          <AddAgencyContainer queryAgency={queryAgency} openAddAgency={openAddAgency} setOpenAddAgency={setOpenAddAgency} setValue={setValue} />
        )
        : null}
    </Box>
  );
}

export {
  AddAgency,
};
