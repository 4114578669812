import React from 'react';
import {
  Box, Button, styled, Typography,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

function TypeOfProperty({ typeOfProperty } : any) {
  const navigate = useNavigate();

  return (
    <Box
      width={{ xs: '90%', sm: '80%' }}
      height={{ xs: 'auto', sm: 'calc(100vh - 100px)' }}
      paddingY={{ xs: 5, sm: 0 }}
      flexDirection="column"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h4" fontWeight={600} width={{ xs: '90%', sm: '80%' }} textAlign="center">
        Sélectionner le type de
        {' '}
        <Text>
          bien
        </Text>
        {' '}
        que vous souhaitez ajouter
      </Typography>
      <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center" marginTop={{ xs: 5, sm: 10 }}>
        {typeOfProperty.map((e: any, i: number) => (
          <Box
            key={e?.text}
            onClick={() => navigate(`${e?.url}?type=${e?.param}`)}
            sx={{
              pointerEvents: e?.disabled ? 'none' : 'inherit',
              cursor: 'pointer',
              borderRadius: '5px',
              border: e?.disabled ? 'none' : '0.1px solid black',
              transition: 'transform 0.3s',
              opacity: e?.disabled ? 0.5 : 1,
              ':hover': {
                transform: 'scale(1.01)',
              },
            }}
            margin={3}
            width={{ xs: '90%', sm: 220 }}
            height={{ xs: 250, sm: 220 }}
            bgcolor="background.paper"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Box width="90%" height="80%" display="flex" alignItems="center" justifyContent="center">
              <Box
                component="img"
                src={e.img}
                height={100}
                sx={{ objectFit: 'cover' }}
              />
            </Box>
            <Box width="90%" height="20%">
              <Typography fontWeight="bold" textAlign="center" variant="body1">{e.disabled ? `${e?.text} (à venir)` : e?.text}</Typography>
            </Box>
          </Box>
        ))}
        <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }} width="100%" marginTop={{ xs: 5, sm: 10 }}>
          <Button
            color="primary"
            onClick={() => navigate('/')}
            variant="contained"
            startIcon={<ArrowBackIosNewIcon />}
            sx={{
              width: '200px',
              height: '50px',
              fontWeight: 'bold',
              color: (theme) => theme.palette.background.paper,
              fontSize: '18px',
              textTransform: 'none',
            }}
          >
            Retour
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

const Text = styled('strong')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export {
  TypeOfProperty,
};
