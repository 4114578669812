import { Link, useLocation } from 'react-router-dom';

export const LinkWithQuery = ({
  children, to, disabled, fullwidth = false, ...props
}: any) => {
  const { search } = useLocation();
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Link
      to={to + search}
      {...props}
      style={{ textDecoration: 'none', pointerEvents: disabled ? 'none' : 'inherit', width: fullwidth ? '100%' : 'auto' }}
    >
      {children}
    </Link>
  );
};
